// vendors
import React from "react"

const IconInstagram = ({ ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 507.26 507.26"
    {...props}
  >
    <rect x="313.68" y="150.69" width="42.89" height="42.89" rx="8.58" />
    <path d="M254,306.61A52.67,52.67,0,1,0,201.16,254,52.72,52.72,0,0,0,254,306.61Z" />
    <path d="M337.45,254a83.5,83.5,0,0,1-167,0c0-7.62,1.51-19.62,6-26H142.11V356.57a8.58,8.58,0,0,0,8.58,8.58H356.57a8.58,8.58,0,0,0,8.58-8.58V227.9H330.84C336.38,234.51,337.45,246.33,337.45,254Z" />
    <path d="M253.63,0C113.55,0,0,113.55,0,253.63S113.55,507.26,253.63,507.26,507.26,393.71,507.26,253.63,393.71,0,253.63,0ZM390.88,358.33a32.59,32.59,0,0,1-32.63,32.55H149a32.59,32.59,0,0,1-32.63-32.55V148.93A32.59,32.59,0,0,1,149,116.38H358.25a32.59,32.59,0,0,1,32.63,32.55Z" />
  </svg>
)

export default IconInstagram
