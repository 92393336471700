// vendors
import React from "react"

const LogoLartere = ({ color, showSubTitle = true, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox={`0 0 451.72 ${showSubTitle ? "160.31" : "64.89"}`}
    {...props}
  >
    <g fill={color}>
      <g>
        <path d="M0,14.1H14.65V52.41H46.79V64.89H0Z" />
        <path d="M63.31,12.29v11L59,30.9H51.71l.84-8.24h0V12.29Z" />
        <path d="M87.94,14.1h15.75l30.39,50.79H116.74L112,56.33H79.67l-4.79,8.56H57.55Zm17,29.75L95.85,27.31,86.64,43.85Z" />
        <path d="M140.63,14.1h41c10.52,0,16.33,5.66,16.33,13.42,0,8.13-6.53,12.77-16.33,12.77H176.4c12.48.8,17.92,8.06,26,24.6H184.52C175.09,48,172.63,44.43,164.65,44.43h-9.43l.07,20.46H140.63Zm38.45,19.23c2.76,0,4.21-1,4.21-3.7s-1.45-3.77-4.21-3.77H155.14l.08,7.47Z" />
        <path d="M226.64,26.58H204.15V14.1h59.71V26.58H241.3V64.89H226.64Z" />
        <path d="M270.37,14.1h51.15V26.58H285v6.6h35.84V45H285v7.4h36.49V64.89H270.37Z" />
        <path d="M332.62,14.1h41c10.52,0,16.32,5.66,16.32,13.42,0,8.13-6.53,12.77-16.32,12.77h-5.22c12.48.8,17.92,8.06,26,24.6H376.51c-9.43-16.91-11.9-20.46-19.88-20.46H347.2l.08,20.46H332.62Zm38.45,19.23c2.76,0,4.21-1,4.21-3.7s-1.45-3.77-4.21-3.77H347.13l.07,7.47Z" />
        <path d="M400.58,14.1h51.14V26.58H415.23v6.6h35.84V45H415.23v7.4h36.49V64.89H400.58Z" />
        <path d="M303.62,7.85H292L287.35,0h15.27Z" />
      </g>
      {showSubTitle && (
        <g>
          <path d="M3.52,132.53a4.47,4.47,0,0,1-1.44-.75,3.2,3.2,0,0,1-.94-1.16A3.3,3.3,0,0,1,.8,129.1a2.74,2.74,0,0,1,.67-1.9A4.76,4.76,0,0,1,3.27,126a11.74,11.74,0,0,1,2.63-.74c1-.16,2.07-.31,3.2-.43v-.23a3.65,3.65,0,0,0-.21-1.32,2,2,0,0,0-.56-.85,2.17,2.17,0,0,0-.82-.44,3.23,3.23,0,0,0-1-.14,2.71,2.71,0,0,0-1.73.55A2.13,2.13,0,0,0,4.06,124H1.38a4.17,4.17,0,0,1,.48-1.72A4.29,4.29,0,0,1,3,121a5.3,5.3,0,0,1,1.61-.87,6.68,6.68,0,0,1,2.05-.31,7.24,7.24,0,0,1,2.07.28,4.27,4.27,0,0,1,1.67.89,4,4,0,0,1,1.1,1.64,6.6,6.6,0,0,1,.37,2.49c0,.15,0,.35,0,.59s0,.51,0,.81,0,.59,0,.9,0,.61,0,.88c0,.78,0,1.55.1,2.31a13.46,13.46,0,0,0,.27,1.91H9.65c0-.26-.09-.49-.12-.71a8.65,8.65,0,0,1-.11-1,3.59,3.59,0,0,1-1.64,1.49,5.71,5.71,0,0,1-2.42.48A6.29,6.29,0,0,1,3.52,132.53ZM6.67,127a8.22,8.22,0,0,0-1.69.46,2.42,2.42,0,0,0-1,.65,1.39,1.39,0,0,0-.31.91,1.48,1.48,0,0,0,.62,1.26,2.67,2.67,0,0,0,1.65.46A3.39,3.39,0,0,0,8.32,130a3,3,0,0,0,.85-2.35v-.94C8.19,126.78,7.36,126.89,6.67,127Z" />
          <path d="M21.15,122.86A4.06,4.06,0,0,0,20,123a1.89,1.89,0,0,0-.85.51,2.24,2.24,0,0,0-.52,1,5.22,5.22,0,0,0-.19,1.54v6.46H15.71V120.16H18.4v2.2a4,4,0,0,1,1.36-1.62,3.51,3.51,0,0,1,2-.6h.36a3.27,3.27,0,0,1,.35,0v2.75C22,122.89,21.56,122.86,21.15,122.86Z" />
          <path d="M29.07,116.9v3.26h2.48v2H29.07v6.72a1.88,1.88,0,0,0,.3,1.25,1.67,1.67,0,0,0,1.17.31h1v2.08h-2a7,7,0,0,1-1.6-.15,1.92,1.92,0,0,1-1-.51,2,2,0,0,1-.47-1,7.68,7.68,0,0,1-.13-1.57v-7.13H24.51v-2h1.83V116.9Z" />
          <path d="M52.23,115.87v16.62H49.5v-1.62A3.91,3.91,0,0,1,48,132.29a4.64,4.64,0,0,1-2.18.5,4.93,4.93,0,0,1-2.08-.45,4.84,4.84,0,0,1-1.7-1.29,6.35,6.35,0,0,1-1.16-2,7.81,7.81,0,0,1-.43-2.68,7.69,7.69,0,0,1,.43-2.66,6.14,6.14,0,0,1,1.16-2,4.86,4.86,0,0,1,1.7-1.3,4.93,4.93,0,0,1,2.08-.45,4.53,4.53,0,0,1,2.18.51,3.83,3.83,0,0,1,1.49,1.42v-5.94Zm-2.87,8.6a3.73,3.73,0,0,0-.68-1.32,2.84,2.84,0,0,0-1-.78,3.07,3.07,0,0,0-1.25-.26,2.72,2.72,0,0,0-1.26.3,2.8,2.8,0,0,0-1,.84,4.23,4.23,0,0,0-.65,1.33,6.3,6.3,0,0,0-.22,1.75,5,5,0,0,0,.87,3.1,2.7,2.7,0,0,0,2.24,1.14,2.93,2.93,0,0,0,1.25-.27,2.65,2.65,0,0,0,1-.79,3.91,3.91,0,0,0,.68-1.32,7,7,0,0,0,0-3.72Z" />
          <path d="M58.71,128.61a3.69,3.69,0,0,0,.78,1.06,3,3,0,0,0,1,.64,3.25,3.25,0,0,0,1.15.21,3.91,3.91,0,0,0,1.87-.42,2.55,2.55,0,0,0,1.16-1.3h2.68a5.41,5.41,0,0,1-.66,1.46,5.2,5.2,0,0,1-1.16,1.28,5.52,5.52,0,0,1-1.64.91,6.87,6.87,0,0,1-4.78-.19,5.85,5.85,0,0,1-2-1.42A6.09,6.09,0,0,1,56,128.76a7.59,7.59,0,0,1-.42-2.53,7,7,0,0,1,.45-2.55,6,6,0,0,1,1.26-2,5.92,5.92,0,0,1,1.93-1.32,6.18,6.18,0,0,1,2.42-.47,5.85,5.85,0,0,1,2.55.55A5.46,5.46,0,0,1,66.08,122a6.62,6.62,0,0,1,1.15,2.28,8.37,8.37,0,0,1,.3,2.87H58.34A4.14,4.14,0,0,0,58.71,128.61Zm1.89-6.44a3.13,3.13,0,0,0-1,.52,3.19,3.19,0,0,0-.82,1,3.69,3.69,0,0,0-.43,1.47h6.42a3.44,3.44,0,0,0-.3-1.3,3.35,3.35,0,0,0-.7-1,3.11,3.11,0,0,0-1-.61,3.25,3.25,0,0,0-1.15-.21A3.76,3.76,0,0,0,60.6,122.17Z" />
          <path d="M77.35,132.49V115.87h2.73v16.62Z" />
          <path d="M86.14,132.53a4.47,4.47,0,0,1-1.44-.75,3.2,3.2,0,0,1-.94-1.16,3.3,3.3,0,0,1-.34-1.52,2.74,2.74,0,0,1,.67-1.9,4.76,4.76,0,0,1,1.8-1.24,11.74,11.74,0,0,1,2.63-.74c1-.16,2.07-.31,3.2-.43v-.23a3.65,3.65,0,0,0-.21-1.32,2,2,0,0,0-.56-.85,2.17,2.17,0,0,0-.82-.44,3.23,3.23,0,0,0-1-.14,2.71,2.71,0,0,0-1.73.55,2.13,2.13,0,0,0-.74,1.67H84a4.17,4.17,0,0,1,.48-1.72,4.29,4.29,0,0,1,1.1-1.34,5.19,5.19,0,0,1,1.61-.87,6.68,6.68,0,0,1,2-.31,7.24,7.24,0,0,1,2.07.28A4.27,4.27,0,0,1,93,121a4,4,0,0,1,1.1,1.64,6.81,6.81,0,0,1,.37,2.49c0,.15,0,.35,0,.59s0,.51,0,.81,0,.59,0,.9,0,.61,0,.88c0,.78,0,1.55.1,2.31a13.46,13.46,0,0,0,.27,1.91h-2.5c0-.26-.09-.49-.13-.71s-.07-.53-.1-1a3.59,3.59,0,0,1-1.64,1.49,5.71,5.71,0,0,1-2.42.48A6.29,6.29,0,0,1,86.14,132.53ZM89.29,127a8.22,8.22,0,0,0-1.69.46,2.42,2.42,0,0,0-1,.65,1.39,1.39,0,0,0-.31.91,1.48,1.48,0,0,0,.62,1.26,2.67,2.67,0,0,0,1.65.46,3.39,3.39,0,0,0,2.36-.77,3,3,0,0,0,.85-2.35v-.94C90.81,126.78,90,126.89,89.29,127Z" />
          <path d="M115.68,115.87v16.62H113v-1.62a3.84,3.84,0,0,1-1.49,1.42,4.61,4.61,0,0,1-2.18.5,4.88,4.88,0,0,1-2.07-.45,5,5,0,0,1-1.71-1.29,6.35,6.35,0,0,1-1.16-2,8.06,8.06,0,0,1-.42-2.68,7.94,7.94,0,0,1,.42-2.66,6.14,6.14,0,0,1,1.16-2,5,5,0,0,1,1.71-1.3,4.88,4.88,0,0,1,2.07-.45,4.5,4.5,0,0,1,2.18.51,3.77,3.77,0,0,1,1.49,1.42v-5.94Zm-2.87,8.6a3.89,3.89,0,0,0-.67-1.32,2.93,2.93,0,0,0-1-.78,3.11,3.11,0,0,0-1.25-.26,2.66,2.66,0,0,0-1.26.3,2.93,2.93,0,0,0-1,.84,4.22,4.22,0,0,0-.64,1.33,6.29,6.29,0,0,0-.23,1.75,5,5,0,0,0,.87,3.1,2.71,2.71,0,0,0,2.25,1.14,3,3,0,0,0,1.25-.27,2.73,2.73,0,0,0,1-.79,4.09,4.09,0,0,0,.67-1.32,7,7,0,0,0,0-3.72Z" />
          <path d="M121.74,132.53a4.47,4.47,0,0,1-1.44-.75,3.2,3.2,0,0,1-.94-1.16,3.43,3.43,0,0,1-.34-1.52,2.79,2.79,0,0,1,.67-1.9,4.76,4.76,0,0,1,1.8-1.24,11.83,11.83,0,0,1,2.64-.74c1-.16,2.06-.31,3.19-.43v-.23a3.9,3.9,0,0,0-.2-1.32,2.12,2.12,0,0,0-.57-.85,2.17,2.17,0,0,0-.82-.44,3.16,3.16,0,0,0-1-.14,2.72,2.72,0,0,0-1.74.55,2.13,2.13,0,0,0-.74,1.67H119.6a4.17,4.17,0,0,1,.48-1.72,4.29,4.29,0,0,1,1.1-1.34,5.24,5.24,0,0,1,1.62-.87,6.58,6.58,0,0,1,2-.31,7.22,7.22,0,0,1,2.06.28,4.27,4.27,0,0,1,1.67.89,4.07,4.07,0,0,1,1.11,1.64,6.82,6.82,0,0,1,.36,2.49c0,.15,0,.35,0,.59s0,.51,0,.81,0,.59,0,.9,0,.61,0,.88q0,1.17.09,2.31a13.46,13.46,0,0,0,.27,1.91h-2.5c0-.26-.08-.49-.12-.71a8.65,8.65,0,0,1-.11-1,3.59,3.59,0,0,1-1.64,1.49,5.7,5.7,0,0,1-2.41.48A6.31,6.31,0,0,1,121.74,132.53Zm3.15-5.52a8.41,8.41,0,0,0-1.69.46,2.42,2.42,0,0,0-1,.65,1.45,1.45,0,0,0-.31.91,1.48,1.48,0,0,0,.62,1.26,2.69,2.69,0,0,0,1.65.46,3.39,3.39,0,0,0,2.36-.77,3,3,0,0,0,.85-2.35v-.94C126.42,126.78,125.58,126.89,124.89,127Z" />
          <path d="M138.15,120.4a4.56,4.56,0,0,1,2.25-.52,4.81,4.81,0,0,1,1.92.35,3.24,3.24,0,0,1,1.29,1,4.18,4.18,0,0,1,.75,1.54,7.7,7.7,0,0,1,.24,2v7.75h-2.73v-7.08a9.87,9.87,0,0,0-.07-1.22,3.2,3.2,0,0,0-.31-1.06,1.77,1.77,0,0,0-.71-.75,2.49,2.49,0,0,0-1.25-.27,2.87,2.87,0,0,0-1.33.28,2.52,2.52,0,0,0-.89.79,3.26,3.26,0,0,0-.49,1.22,7.38,7.38,0,0,0-.15,1.54v6.55h-2.73V120.16h2.73v1.72A3.58,3.58,0,0,1,138.15,120.4Z" />
          <path d="M154.6,122.32a4,4,0,0,0-1.75-.35,3.28,3.28,0,0,0-1.59.37,1.09,1.09,0,0,0-.7,1,1.15,1.15,0,0,0,.42,1,2.82,2.82,0,0,0,1.23.51l1.74.34a14.71,14.71,0,0,1,1.74.44,4.8,4.8,0,0,1,1.4.68,3,3,0,0,1,.93,1,3.18,3.18,0,0,1,.33,1.51,3.44,3.44,0,0,1-.42,1.71,3.81,3.81,0,0,1-1.15,1.25,5.64,5.64,0,0,1-1.66.77,7.59,7.59,0,0,1-2,.26,6.45,6.45,0,0,1-2.08-.31,5.42,5.42,0,0,1-1.62-.85,4.36,4.36,0,0,1-1.65-2.9h2.66a2.33,2.33,0,0,0,.87,1.48,3.16,3.16,0,0,0,1.91.52,3.27,3.27,0,0,0,1.77-.43,1.3,1.3,0,0,0,.7-1.18,1.25,1.25,0,0,0-.6-1.13,4.44,4.44,0,0,0-1.53-.56l-1.86-.37a9.86,9.86,0,0,1-1.45-.42,4.26,4.26,0,0,1-1.18-.69,3.06,3.06,0,0,1-.79-1,3.31,3.31,0,0,1-.29-1.46,2.77,2.77,0,0,1,.41-1.48,3.78,3.78,0,0,1,1.1-1.12,5,5,0,0,1,1.6-.72,7,7,0,0,1,1.91-.26,7.15,7.15,0,0,1,1.74.22,5,5,0,0,1,1.54.68,4.37,4.37,0,0,1,1.17,1.14,4.21,4.21,0,0,1,.66,1.61h-2.56A1.8,1.8,0,0,0,154.6,122.32Z" />
          <path d="M164.05,128.61a3.69,3.69,0,0,0,.78,1.06,3,3,0,0,0,1,.64,3.3,3.3,0,0,0,1.16.21,3.94,3.94,0,0,0,1.87-.42,2.64,2.64,0,0,0,1.16-1.3h2.68a5.45,5.45,0,0,1-.67,1.46,5,5,0,0,1-1.16,1.28,5.59,5.59,0,0,1-1.63.91,6.87,6.87,0,0,1-4.78-.19,5.79,5.79,0,0,1-2-1.42,6.25,6.25,0,0,1-1.21-2.08,7.85,7.85,0,0,1-.41-2.53,7.18,7.18,0,0,1,.44-2.55,5.85,5.85,0,0,1,3.19-3.33,6.21,6.21,0,0,1,2.42-.47,5.9,5.9,0,0,1,2.56.55,5.42,5.42,0,0,1,1.9,1.52,6.63,6.63,0,0,1,1.16,2.28,8.13,8.13,0,0,1,.29,2.87h-9.19A4.13,4.13,0,0,0,164.05,128.61Zm1.88-6.44a3.24,3.24,0,0,0-1,.52,3.31,3.31,0,0,0-.81,1,3.69,3.69,0,0,0-.44,1.47h6.42a3.1,3.1,0,0,0-.3-1.3,3,3,0,0,0-.7-1,3.18,3.18,0,0,0-1-.61A3.3,3.3,0,0,0,167,122,3.82,3.82,0,0,0,165.93,122.17Z" />
          <path d="M3.86,156.12a3.74,3.74,0,0,0,.78,1.07,3.15,3.15,0,0,0,1,.64,3.2,3.2,0,0,0,1.15.2,3.94,3.94,0,0,0,1.87-.42,2.53,2.53,0,0,0,1.16-1.29h2.68a5.67,5.67,0,0,1-.66,1.45,5.41,5.41,0,0,1-1.16,1.28,5.78,5.78,0,0,1-1.64.91,6.18,6.18,0,0,1-2.13.35,6.33,6.33,0,0,1-2.65-.53,5.89,5.89,0,0,1-2-1.42,6.15,6.15,0,0,1-1.22-2.09,7.79,7.79,0,0,1-.41-2.52,7,7,0,0,1,.45-2.56,6,6,0,0,1,1.26-2,5.53,5.53,0,0,1,1.93-1.32,6,6,0,0,1,2.41-.47,5.76,5.76,0,0,1,2.56.55,5.31,5.31,0,0,1,1.9,1.51,6.63,6.63,0,0,1,1.16,2.28,8.4,8.4,0,0,1,.3,2.88H3.48A4.34,4.34,0,0,0,3.86,156.12Zm1.88-6.43a3.24,3.24,0,0,0-1,.52,3.06,3.06,0,0,0-.81.95,3.59,3.59,0,0,0-.44,1.46H9.9a3.21,3.21,0,0,0-.29-1.29,3.27,3.27,0,0,0-.7-1,3.2,3.2,0,0,0-1-.6,3.07,3.07,0,0,0-1.16-.22A3.48,3.48,0,0,0,5.74,149.69Z" />
          <path d="M19.05,144.42v3.25h2.48v2H19.05v6.72a1.86,1.86,0,0,0,.3,1.25,1.63,1.63,0,0,0,1.17.31h1V160h-2a7,7,0,0,1-1.6-.15,1.86,1.86,0,0,1-1-.52,1.91,1.91,0,0,1-.47-1,7.85,7.85,0,0,1-.13-1.58v-7.13H14.49v-2h1.83v-3.25Z" />
          <path d="M42.21,143.39V160H39.48v-1.63A3.91,3.91,0,0,1,38,159.8a4.52,4.52,0,0,1-2.18.5A4.84,4.84,0,0,1,32,158.56a6.14,6.14,0,0,1-1.16-2,8.5,8.5,0,0,1,0-5.34,6.25,6.25,0,0,1,1.16-2,4.86,4.86,0,0,1,1.7-1.3,5,5,0,0,1,2.08-.44,4.64,4.64,0,0,1,2.18.5,3.91,3.91,0,0,1,1.49,1.42v-5.93ZM39.34,152a3.91,3.91,0,0,0-.68-1.32,2.7,2.7,0,0,0-1-.77,2.93,2.93,0,0,0-1.25-.27,2.72,2.72,0,0,0-1.26.3,2.83,2.83,0,0,0-1,.85,4.13,4.13,0,0,0-.65,1.33,6.28,6.28,0,0,0-.22,1.74,5,5,0,0,0,.87,3.11,2.72,2.72,0,0,0,2.24,1.13,3.07,3.07,0,0,0,1.25-.26,2.76,2.76,0,0,0,1-.79,4,4,0,0,0,.68-1.32,6.14,6.14,0,0,0,.25-1.87A6.2,6.2,0,0,0,39.34,152Z" />
          <path d="M52.51,159.79a4.49,4.49,0,0,1-2.19.51,4.61,4.61,0,0,1-1.88-.34,3.41,3.41,0,0,1-1.29-1,4.23,4.23,0,0,1-.73-1.54,8.18,8.18,0,0,1-.23-2v-7.78h2.73v7.11A9.6,9.6,0,0,0,49,156a3.18,3.18,0,0,0,.29,1.07,1.91,1.91,0,0,0,.69.75,2.4,2.4,0,0,0,1.22.27,2.62,2.62,0,0,0,1.29-.29,2.18,2.18,0,0,0,.86-.79,3.48,3.48,0,0,0,.47-1.21,7.52,7.52,0,0,0,.15-1.54v-6.58h2.73V160H54v-1.7A3.75,3.75,0,0,1,52.51,159.79Z" />
          <path d="M67.33,160V147.67h2.73v1.72a3.77,3.77,0,0,1,1.43-1.48,4.33,4.33,0,0,1,2.15-.51,4.1,4.1,0,0,1,2.3.57,3.6,3.6,0,0,1,1.3,1.61,4.83,4.83,0,0,1,1.83-1.68,5,5,0,0,1,2.18-.5,4.51,4.51,0,0,1,1.86.34,3.07,3.07,0,0,1,1.25,1,4,4,0,0,1,.7,1.53,8.22,8.22,0,0,1,.22,2V160H82.55v-7.09c0-.41,0-.82,0-1.21a3.18,3.18,0,0,0-.28-1.07,1.67,1.67,0,0,0-.65-.74,2.16,2.16,0,0,0-1.19-.28,2.63,2.63,0,0,0-1.28.29,2.24,2.24,0,0,0-.83.79,3.36,3.36,0,0,0-.46,1.21,8.32,8.32,0,0,0-.14,1.54V160H74.94v-7.09c0-.41,0-.82,0-1.21a3.18,3.18,0,0,0-.28-1.07,1.73,1.73,0,0,0-.65-.74,2.16,2.16,0,0,0-1.19-.28,2.63,2.63,0,0,0-1.28.29,2.24,2.24,0,0,0-.83.79,3.36,3.36,0,0,0-.46,1.21,8.32,8.32,0,0,0-.14,1.54V160Z" />
          <path d="M96.82,147.79a5.58,5.58,0,0,1,2,1.18,5.73,5.73,0,0,1,1.39,2,8.14,8.14,0,0,1,0,5.78,5.77,5.77,0,0,1-1.39,2,5.41,5.41,0,0,1-2,1.17,6.79,6.79,0,0,1-4.42,0,5.41,5.41,0,0,1-2-1.17,5.91,5.91,0,0,1-1.39-2,8.29,8.29,0,0,1,0-5.78,5.87,5.87,0,0,1,1.39-2,5.58,5.58,0,0,1,2-1.18,6.46,6.46,0,0,1,4.42,0Zm-5.2,7.94a3.9,3.9,0,0,0,.71,1.31,3,3,0,0,0,3.52.78,3,3,0,0,0,1-.78,3.73,3.73,0,0,0,.71-1.31,6.54,6.54,0,0,0,0-3.72,3.74,3.74,0,0,0-.71-1.33,2.72,2.72,0,0,0-1-.79,3,3,0,0,0-2.48,0,2.8,2.8,0,0,0-1,.79,3.9,3.9,0,0,0-.71,1.33,6.78,6.78,0,0,0,0,3.72Z" />
          <path d="M110.22,159.79a4.49,4.49,0,0,1-2.19.51,4.64,4.64,0,0,1-1.88-.34,3.3,3.3,0,0,1-1.28-1,4.24,4.24,0,0,1-.74-1.54,8.19,8.19,0,0,1-.22-2v-7.78h2.72v7.11a11.92,11.92,0,0,0,.07,1.21,3.43,3.43,0,0,0,.3,1.07,1.78,1.78,0,0,0,.69.75,2.36,2.36,0,0,0,1.21.27,2.63,2.63,0,0,0,1.3-.29,2.25,2.25,0,0,0,.86-.79,3.68,3.68,0,0,0,.47-1.21,7.52,7.52,0,0,0,.15-1.54v-6.58h2.72V160h-2.72v-1.7A3.78,3.78,0,0,1,110.22,159.79Z" />
          <path d="M125.77,147.67h2.85L124.17,160H121.6l-4.49-12.34h3l2.85,8.81Z" />
          <path d="M133.3,156.12a3.4,3.4,0,0,0,.78,1.07,3,3,0,0,0,1,.64,3.26,3.26,0,0,0,1.16.2,3.94,3.94,0,0,0,1.87-.42,2.51,2.51,0,0,0,1.15-1.29H142a5.39,5.39,0,0,1-.67,1.45,5,5,0,0,1-1.16,1.28,5.52,5.52,0,0,1-1.64.91,6.1,6.1,0,0,1-2.13.35,6.4,6.4,0,0,1-2.65-.53,6,6,0,0,1-2-1.42,6.13,6.13,0,0,1-1.21-2.09,7.52,7.52,0,0,1-.41-2.52,7,7,0,0,1,.44-2.56,6,6,0,0,1,1.26-2,5.72,5.72,0,0,1,1.93-1.32,6,6,0,0,1,2.42-.47,5.72,5.72,0,0,1,2.55.55,5.43,5.43,0,0,1,1.91,1.51,6.81,6.81,0,0,1,1.16,2.28,8.66,8.66,0,0,1,.29,2.88h-9.19A4.13,4.13,0,0,0,133.3,156.12Zm1.88-6.43a3.13,3.13,0,0,0-1,.52,2.82,2.82,0,0,0-.81.95,3.59,3.59,0,0,0-.44,1.46h6.42a3.39,3.39,0,0,0-.3-1.29,3.11,3.11,0,0,0-.7-1,3.15,3.15,0,0,0-1-.6,3.1,3.1,0,0,0-1.16-.22A3.48,3.48,0,0,0,135.18,149.69Z" />
          <path d="M145.48,160V147.67h2.73v1.72a3.77,3.77,0,0,1,1.43-1.48,4.33,4.33,0,0,1,2.15-.51,4.1,4.1,0,0,1,2.3.57,3.47,3.47,0,0,1,1.3,1.61,4.83,4.83,0,0,1,1.83-1.68,5,5,0,0,1,2.18-.5,4.56,4.56,0,0,1,1.87.34,3.14,3.14,0,0,1,1.25,1,4.3,4.3,0,0,1,.7,1.53,8.77,8.77,0,0,1,.21,2V160h-2.72v-7.09a12,12,0,0,0-.06-1.21,3.18,3.18,0,0,0-.28-1.07,1.67,1.67,0,0,0-.65-.74,2.16,2.16,0,0,0-1.19-.28,2.57,2.57,0,0,0-1.27.29,2.2,2.2,0,0,0-.84.79,3.55,3.55,0,0,0-.46,1.21,8.32,8.32,0,0,0-.14,1.54V160h-2.73v-7.09a12,12,0,0,0-.05-1.21,3.18,3.18,0,0,0-.28-1.07,1.67,1.67,0,0,0-.65-.74,2.16,2.16,0,0,0-1.19-.28,2.63,2.63,0,0,0-1.28.29,2.24,2.24,0,0,0-.83.79,3.36,3.36,0,0,0-.46,1.21,8.32,8.32,0,0,0-.14,1.54V160Z" />
          <path d="M169.8,156.12a3.74,3.74,0,0,0,.78,1.07,3.15,3.15,0,0,0,1,.64,3.24,3.24,0,0,0,1.15.2,3.94,3.94,0,0,0,1.87-.42,2.53,2.53,0,0,0,1.16-1.29h2.68a5.67,5.67,0,0,1-.66,1.45,5.41,5.41,0,0,1-1.16,1.28A5.65,5.65,0,0,1,175,160a6.18,6.18,0,0,1-2.13.35,6.33,6.33,0,0,1-2.65-.53,5.89,5.89,0,0,1-2-1.42,6.15,6.15,0,0,1-1.22-2.09,7.79,7.79,0,0,1-.41-2.52,7,7,0,0,1,.45-2.56,6,6,0,0,1,1.26-2,5.53,5.53,0,0,1,1.93-1.32,6,6,0,0,1,2.41-.47,5.76,5.76,0,0,1,2.56.55,5.31,5.31,0,0,1,1.9,1.51,6.63,6.63,0,0,1,1.16,2.28,8.4,8.4,0,0,1,.3,2.88h-9.2A4.34,4.34,0,0,0,169.8,156.12Zm1.88-6.43a3.24,3.24,0,0,0-1,.52,3.06,3.06,0,0,0-.81.95,3.75,3.75,0,0,0-.44,1.46h6.42a3.21,3.21,0,0,0-.29-1.29,3.27,3.27,0,0,0-.7-1,3.2,3.2,0,0,0-1-.6,3.07,3.07,0,0,0-1.16-.22A3.48,3.48,0,0,0,171.68,149.69Z" />
          <path d="M186.2,147.91a4.67,4.67,0,0,1,2.25-.51,4.81,4.81,0,0,1,1.92.34,3.19,3.19,0,0,1,1.29,1,4.21,4.21,0,0,1,.75,1.53,7.72,7.72,0,0,1,.24,2V160h-2.73v-7.09a9.6,9.6,0,0,0-.07-1.21,3,3,0,0,0-.31-1.07,1.74,1.74,0,0,0-.71-.74,2.39,2.39,0,0,0-1.25-.28,2.87,2.87,0,0,0-1.33.29,2.42,2.42,0,0,0-.89.79,3.38,3.38,0,0,0-.49,1.21,7.52,7.52,0,0,0-.15,1.54V160H182V147.67h2.73v1.72A3.64,3.64,0,0,1,186.2,147.91Z" />
          <path d="M199.69,144.42v3.25h2.48v2h-2.48v6.72a1.86,1.86,0,0,0,.3,1.25,1.61,1.61,0,0,0,1.17.31h1V160h-2a7,7,0,0,1-1.6-.15,1.86,1.86,0,0,1-1-.52,1.91,1.91,0,0,1-.47-1,7.85,7.85,0,0,1-.13-1.58v-7.13h-1.83v-2H197v-3.25Z" />
        </g>
      )}
    </g>
  </svg>
)

export default LogoLartere
