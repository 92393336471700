// vendors
import React from "react"

const LogoPremiereOvation = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 446.86 174.04"
    {...props}
  >
    <g>
      <path d="M123.22,41,111.3,37.82V2.23c0-2-1.4-2.79-3.12-1.83L63.89,25,3.44,8.65A2.59,2.59,0,0,0,0,11.28V128.57a4.92,4.92,0,0,0,3.44,4.49l11.88,3.22v35.53c0,2,1.4,2.78,3.11,1.83l44.23-24.56,60.56,16.38a2.59,2.59,0,0,0,3.44-2.63V45.54A4.92,4.92,0,0,0,123.22,41ZM106.55,6.74V36.53L70.47,26.77ZM65.75,30.42l40.8,11v77.82l-40.8,22.66Zm-61,98.08V13.92L57.93,28.31,18.43,50.24a6.69,6.69,0,0,0-3.11,5.29v75.83Zm117.16,31.68L68.62,145.77l39.56-22a6.68,6.68,0,0,0,3.12-5.29V42.74l10.61,2.87Z" />
      <g>
        <path d="M167.87,165.9c-12.72,0-22.76-8.61-22.76-21.77s10-21.89,22.76-21.89,22.7,8.79,22.7,21.89S180.59,165.9,167.87,165.9Zm0-35.24c-7.61,0-13.22,5.86-13.22,13.47s5.61,13.41,13.22,13.41S181,151.74,181,144.13,175.48,130.66,167.87,130.66Z" />
        <path d="M216.38,164.52a1.31,1.31,0,0,1-1.43.94h-7.48a1.35,1.35,0,0,1-1.5-.94L190.5,124.11c-.31-.75-.06-1.31.75-1.31h7.55a1.44,1.44,0,0,1,1.56,1.06l10.79,30.31h.12l11.16-30.31A1.4,1.4,0,0,1,224,122.8h7.49c.75,0,1.06.56.75,1.31Z" />
        <path d="M236.34,164.21a1.44,1.44,0,0,1-1.56,1.06h-7c-.62,0-.87-.31-.87-.75a2.46,2.46,0,0,1,.13-.62l14.53-40.29c.18-.75.68-1,1.49-1h7.42c.82,0,1.31.24,1.5,1l14.35,40.29a2.9,2.9,0,0,1,.12.62c0,.44-.25.75-.87.75h-7.18c-.87,0-1.37-.31-1.55-1.06l-2.56-7.54H238.89Zm10.35-30.68-5.55,16h11Z" />
        <path d="M299,122.8a1,1,0,0,1,1,.94v6.11a1,1,0,0,1-1,.93H286.29v33.56a1,1,0,0,1-.94.93h-7.11a1,1,0,0,1-.93-.93V130.78H264.59a1,1,0,0,1-.94-.93v-6.11a1,1,0,0,1,.94-.94Z" />
        <path d="M312.54,122.8a.93.93,0,0,1,.94.94v40.6a.92.92,0,0,1-.94.93h-7.05a1,1,0,0,1-1-.93v-40.6a1,1,0,0,1,1-.94Z" />
        <path d="M341.54,165.9c-12.72,0-22.76-8.61-22.76-21.77s10-21.89,22.76-21.89,22.7,8.79,22.7,21.89S354.26,165.9,341.54,165.9Zm0-35.24c-7.61,0-13.22,5.86-13.22,13.47s5.61,13.41,13.22,13.41,13.16-5.8,13.16-13.41S349.15,130.66,341.54,130.66Z" />
        <path d="M399,123.74a1,1,0,0,1,.94-.94h6.73a1,1,0,0,1,.94.94v40.6a1,1,0,0,1-.94.93h-6.23a1.77,1.77,0,0,1-1.75-.93l-20.39-26.57v26.57a1,1,0,0,1-.94.93h-6.67a1,1,0,0,1-1-.93v-40.6a1,1,0,0,1,1-.94H377a1.86,1.86,0,0,1,1.68,1L399,150.37Z" />
      </g>
      <g>
        <path d="M166.06,70.11a15.47,15.47,0,0,1,10.17,3.18A14.71,14.71,0,0,1,181.4,85a14.75,14.75,0,0,1-5.61,12,15.25,15.25,0,0,1-9.73,2.87h-9.67v11.84a.92.92,0,0,1-.93.94h-7.05a1,1,0,0,1-1-.94V71a1,1,0,0,1,1-.93Zm3.55,9.1A8.57,8.57,0,0,0,164.87,78h-8.48v14h8.3a8.66,8.66,0,0,0,4.8-1.13c1.75-1.06,2.81-3,2.81-5.86S171.24,80.28,169.61,79.21Z" />
        <path d="M212.77,96.61,222,111c.43.75.5,1.62-.56,1.62h-7.8a1.67,1.67,0,0,1-1.81-.94l-8.35-13.78h-8v13.78a.92.92,0,0,1-.94.94h-7a1,1,0,0,1-1-.94V71a1,1,0,0,1,1-.93h18.21c4.11,0,7.86.75,10.72,2.87,3.12,2.31,4.93,6.3,4.93,10.91a13.16,13.16,0,0,1-4.74,10.42A14.4,14.4,0,0,1,212.77,96.61ZM195.43,77.72V90.25h8.73c2.31,0,4.18-.18,5.49-1.06a6.6,6.6,0,0,0,.19-10.29,9.09,9.09,0,0,0-4.93-1.18Z" />
        <path d="M258.35,70.11a1,1,0,0,1,1,.93v6.12a1,1,0,0,1-1,.93H236.28v9.17h18.83a1,1,0,0,1,.93.94v6a1,1,0,0,1-.93,1H236.28v9.42h22.07a.94.94,0,0,1,1,.94v6a1,1,0,0,1-1,.94H228.29a1,1,0,0,1-1-.94V71a1,1,0,0,1,1-.93Z" />
        <path d="M310.3,111.64a1,1,0,0,1-1,.94h-6.74a1,1,0,0,1-1-.94V85.7l-9.05,15.78a1.47,1.47,0,0,1-1.49,1h-5.18a1.54,1.54,0,0,1-1.5-1L275.06,85.7v25.94a1,1,0,0,1-1,.94h-6.73a1,1,0,0,1-1-.94V71a1,1,0,0,1,1-.93h7.11a1.66,1.66,0,0,1,1.74,1l12.1,21,12.16-21a1.57,1.57,0,0,1,1.69-1h7.17a1,1,0,0,1,1,.93Z" />
        <path d="M326.26,70.11a.92.92,0,0,1,.93.93v40.6a.92.92,0,0,1-.93.94h-7.05a1,1,0,0,1-1-.94V71a1,1,0,0,1,1-.93Z" />
        <path d="M400,96.61,409.26,111c.44.75.5,1.62-.56,1.62h-7.8a1.67,1.67,0,0,1-1.81-.94l-8.35-13.78h-8.05v13.78a.92.92,0,0,1-.93.94h-7.05a1,1,0,0,1-1-.94V71a1,1,0,0,1,1-.93h18.21c4.12,0,7.86.75,10.73,2.87,3.12,2.31,4.92,6.3,4.92,10.91a13.13,13.13,0,0,1-4.74,10.42A14.25,14.25,0,0,1,400,96.61ZM382.69,77.72V90.25h8.73c2.31,0,4.18-.18,5.49-1.06a6.6,6.6,0,0,0,.19-10.29,9.07,9.07,0,0,0-4.93-1.18Z" />
        <path d="M445.86,70.11a1,1,0,0,1,1,.93v6.12a1,1,0,0,1-1,.93H423.79v9.17h18.83a1,1,0,0,1,.94.94v6a1,1,0,0,1-.94,1H423.79v9.42h22.07a.94.94,0,0,1,1,.94v6a1,1,0,0,1-1,.94H415.8a1,1,0,0,1-1-.94V71a1,1,0,0,1,1-.93Z" />
        <g>
          <path d="M366.48,70.11H336.42a1,1,0,0,0-1,.93v40.6a1,1,0,0,0,1,.94h30.06a1,1,0,0,0,1-.94v-6a.94.94,0,0,0-1-.94H344.4V95.24h18.84a1,1,0,0,0,.93-1v-6a1,1,0,0,0-.93-.94H344.4V78.09h22.08a1,1,0,0,0,1-.93V71A1,1,0,0,0,366.48,70.11Z" />
          <path d="M366.48,64.31H336.42a1,1,0,0,0-1,.94v1.39a1,1,0,0,0,1,.93h30.06a1,1,0,0,0,1-.93V65.25A1,1,0,0,0,366.48,64.31Z" />
        </g>
      </g>
    </g>
  </svg>
)

export default LogoPremiereOvation
