// vendors
import React from "react"

const LogoVilleDeQuebecEntenteDeDeveloppementCulturel = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 523.22 245.17"
    {...props}
  >
    <title>logo-ville-de-quebec-entente-de-developpement-culturel</title>
    <g>
      <g>
        <path d="M1.13,0H29.32V6.35h-21V17H28.89v6.36H8.35V34.79h21v6.35H1.13Z" />
        <path d="M46.42,11.17v30H39.2V0h7.4l21,29.18V0h7.21V41.14h-7Z" />
        <path d="M95.9,6.35H83.44V0h32.14V6.35H103.12V41.14H95.9Z" />
        <path d="M124.17,0h28.19V6.35h-21V17h20.54v6.36H131.38V34.79h21v6.35H124.17Z" />
        <path d="M169.45,11.17v30h-7.22V0h7.41l21,29.18V0h7.22V41.14h-7Z" />
        <path d="M218.94,6.35H206.48V0h32.14V6.35H226.16V41.14h-7.22Z" />
        <path d="M247.2,0h28.19V6.35h-21V17H275v6.36H254.42V34.79h21v6.35H247.2Z" />
      </g>
      <g>
        <path d="M199.14,69.39h-4.75v5.78h4.75a2.9,2.9,0,1,0,0-5.78Z" />
        <path d="M174.57,69.17c-3.87,0-6.35,3-6.35,6.88s2.48,6.89,6.35,6.89S181,80,181,76.05,178.43,69.17,174.57,69.17Z" />
        <path d="M17.62,69.48h-3.8V82.59h3.8a6.27,6.27,0,0,0,6.57-6.54C24.19,72.34,21.93,69.48,17.62,69.48Z" />
        <path d="M70.32,69.48h-3.8V82.59h3.8a6.27,6.27,0,0,0,6.57-6.54C76.89,72.34,74.63,69.48,70.32,69.48Z" />
        <path d="M220,69.39h-4.75v5.78H220a2.9,2.9,0,1,0,0-5.78Z" />
        <path d="M188.48,50.21l-11.3-16.14V50.21H.78V100H523.22V50.21ZM96.22,59.27H99.9L94.81,63.8H92ZM17.62,86.52H9.36v-21h8.26c6.57,0,11.13,4.18,11.13,10.49S24.19,86.52,17.62,86.52ZM48,69.39H37.64V74H47.79v3.83H37.64v4.87H48v3.87H33.18v-21H48ZM70.32,86.52H62.06v-21h8.26c6.57,0,11.13,4.18,11.13,10.49S76.89,86.52,70.32,86.52Zm30.39-17.13H90.34V74h10.15v3.83H90.34v4.87h10.37v3.87H85.88v-21h14.83Zm16.22,17.13h-5.59l-8.11-21h5.09l5.81,16.12,5.79-16.12H125Zm26.12-17.13H132.67V74h10.16v3.83H132.67v4.87h10.38v3.87H128.21v-21h14.84Zm18.06,17.13H147.79v-21h4.46v17h8.86Zm13.46.38a10.85,10.85,0,1,1,11-10.85A10.53,10.53,0,0,1,174.57,86.9ZM199.74,79h-5.35v7.51h-4.46v-21h9.81c4.55,0,7,3.08,7,6.75S204.26,79,199.74,79Zm20.86,0h-5.34v7.51H210.8v-21h9.8c4.56,0,7.05,3.08,7.05,6.75S225.13,79,220.6,79Zm25.9-9.62H236.13V74h10.15v3.83H236.13v4.87H246.5v3.87H231.67v-21H246.5ZM274,86.52h-4.5V71.43l-5.9,15.09h-1.95l-5.91-15.09V86.52h-4.47v-21h6.26l5.09,13.07,5.09-13.07H274ZM294.2,69.39H283.83V74H294v3.83H283.83v4.87H294.2v3.87H279.37v-21H294.2Zm23.51,17.13h-4.3l-10-14.08V86.52H299v-21h4.59l9.71,13.57V65.56h4.46Zm20.84-17h-6.1v17H328v-17h-6.13V65.56h16.72ZM362,82.94a5.67,5.67,0,0,0,5.09-3l3.83,1.85A9.71,9.71,0,0,1,362,86.9a10.85,10.85,0,1,1,0-21.69,9.65,9.65,0,0,1,8.92,5.12l-3.83,1.89a5.73,5.73,0,0,0-5.09-3c-3.65,0-6.41,2.92-6.41,6.88S358.31,82.94,362,82.94Zm31.59-4.81c0,5.21-3,8.77-9.34,8.77s-9.4-3.59-9.4-8.74V65.56h4.53V78c0,2.92,1.6,4.94,4.87,4.94S389,80.92,389,78V65.56h4.53Zm18.72,8.39H399v-21h4.46v17h8.86Zm16.66-17h-6.1v17h-4.49v-17h-6.13V65.56h16.72Zm22.85,8.65c0,5.21-3,8.77-9.34,8.77s-9.39-3.59-9.39-8.74V65.56h4.52V78c0,2.92,1.6,4.94,4.87,4.94s4.81-2,4.81-4.94V65.56h4.53ZM469,86.52,464.91,79h-3.27v7.51h-4.46v-21H467c4.37,0,7,2.86,7,6.75a6,6,0,0,1-4.59,6.23l4.71,8Zm24.45-17.13H483.11V74h10.15v3.83H483.11v4.87h10.37v3.87H478.65v-21h14.83Zm18.07,17.13H498.22v-21h4.46v17h8.87Z" />
        <path d="M466.32,69.39h-4.68v5.78h4.68a2.9,2.9,0,1,0,0-5.78Z" />
      </g>
      <g>
        <g>
          <path d="M316.3,225.4V201.69h-.18c-.84.77-4,.84-5.28.84h-2.29v.19c2.65,1.62,2.29,4,2.29,7v10c0,5-4.93,8.54-8.9,8.54-5.24,0-7.41-3.72-7.41-9.71V201.69h-.18c-.84.77-4,.84-5.3.84h-2.27v.19c2.65,1.63,2.29,4,2.29,7v10c0,9.15,4,13.5,11.06,13.5,4.22,0,8.54-1.49,10.71-5.06v4.32h7.75v-.19C315.94,230.81,316.3,228.4,316.3,225.4Z" />
          <path d="M328.32,212.72a7.63,7.63,0,0,1,7.77-6.66c4.33,0,7.1,2.14,7.76,6.66Zm21.85,3.78c.24-8.37-5.36-14.68-13.54-14.68-9,0-15.05,6.51-15.05,15.53s7,16,17.27,16a20.39,20.39,0,0,0,8.31-1.61l3-6.1H350a15.09,15.09,0,0,1-9.87,3.47c-6.8,0-11.92-4.65-12-12.63Z" />
          <path d="M355,187.91c1.44,0,4.38-.09,5.27-1h.18V228a18.59,18.59,0,0,0,5,.77c6.58,0,10.35-4.6,10.35-11s-2.93-11.14-9-11.14a9.79,9.79,0,0,0-5,1.42l3.23-5.53a11.94,11.94,0,0,1,4.25-.71c6.82,0,12.62,5.92,12.62,14.79,0,10.17-6.88,16.75-17.77,16.75a70.53,70.53,0,0,1-10.91-1.17V232c2-.91,1.8-3.07,1.8-5.21V196.93c0-3,.36-7.19-2.22-8.82v-.2Z" />
          <path d="M392.49,212.72a7.64,7.64,0,0,1,7.77-6.66c4.33,0,7.1,2.14,7.76,6.66Zm21.85,3.78c.24-8.37-5.36-14.68-13.54-14.68-9,0-15.05,6.51-15.05,15.53s7,16,17.27,16a20.35,20.35,0,0,0,8.31-1.61l3-6.1h-.18a15.09,15.09,0,0,1-9.87,3.47c-6.8,0-11.92-4.65-12-12.63Z" />
          <path d="M441.26,209.26h-.18a9.54,9.54,0,0,0-7.35-3.2c-5.83,0-10,4.43-10,10.62a12.24,12.24,0,0,0,12.51,12.45,14.8,14.8,0,0,0,8.79-3.2h.18L442,232.08a21,21,0,0,1-8,1.29c-9.26,0-16.51-6.52-16.51-15,0-11.21,8.09-16.56,17.18-16.56a27.38,27.38,0,0,1,6.5.77Z" />
          <path
            d="M342.66,196.44v-6.51a27.66,27.66,0,0,0-10.05,8.29v2.54A27.49,27.49,0,0,1,342.66,196.44Z"
            fillRule="evenodd"
          />
          <path d="M262,228.89c-9.09,0-15.41-10.25-15.41-19v-1c.11-8.44,5.58-16.36,15.11-16.36,10,0,15.2,9.49,15.29,18.48V212C277,221.59,271,228.89,262,228.89Zm31.15,10c-6.71,1.1-13.16-1-20-8.86,6.62-3.33,10.71-11.6,10.71-19.29v-1c-.12-13.16-10-21.85-21.79-21.85a22.1,22.1,0,0,0-22.33,22.31v.89c0,12.9,9.57,22.48,21.31,22.48a21,21,0,0,0,4.93-.65c7.69,7.2,14.54,10.27,20.46,9.55,2.81-.33,5.78-1.45,7.56-3.76Z" />
        </g>
        <g>
          <path
            d="M455.94,205.92h29.35v-18H455.94ZM467,195.29c1.5.83,2.12,2.69,2.12,4.68H470a8.94,8.94,0,0,0-.13-2.7,15.68,15.68,0,0,0-1-2.78,3.53,3.53,0,0,1,.33-3.65,21.89,21.89,0,0,0,1.5-2,20.06,20.06,0,0,0,1.5,2,3.55,3.55,0,0,1,.33,3.65,15.8,15.8,0,0,0-1,2.78,8.94,8.94,0,0,0-.13,2.7h.82c0-2,.61-3.85,2.11-4.68a2.47,2.47,0,0,1,3.56,3c-.35,1.11-1,1.49-1.15,1.49a1.88,1.88,0,0,0-.6-1.86,1.68,1.68,0,0,0-2.35.47,3.79,3.79,0,0,0-.52,1.59h1.53v1.13h-3.07a1.51,1.51,0,0,0,1.76,1.44s-.2,1.26-1.76.6a2.33,2.33,0,0,1-1,2,2.3,2.3,0,0,1-1-2c-1.56.66-1.77-.6-1.77-.6a1.52,1.52,0,0,0,1.77-1.44h-3.08V200h1.53a3.79,3.79,0,0,0-.52-1.59,1.68,1.68,0,0,0-2.35-.47,1.89,1.89,0,0,0-.59,1.86c-.18,0-.81-.38-1.16-1.49A2.47,2.47,0,0,1,467,195.29Z"
            fillRule="evenodd"
          />
          <path
            d="M493.85,187.92v18h29.37v-18Zm21.83,10.36c-.35,1.11-1,1.49-1.14,1.49a1.92,1.92,0,0,0-.61-1.86,1.68,1.68,0,0,0-2.35.47,4,4,0,0,0-.52,1.59h1.53v1.13h-3.08a1.52,1.52,0,0,0,1.78,1.44s-.22,1.26-1.78.6a2.31,2.31,0,0,1-1,2,2.27,2.27,0,0,1-1-2c-1.56.66-1.77-.6-1.77-.6a1.53,1.53,0,0,0,1.77-1.44h-3.07V200H506a3.79,3.79,0,0,0-.52-1.59,1.67,1.67,0,0,0-2.34-.47,1.88,1.88,0,0,0-.6,1.86c-.18,0-.81-.38-1.16-1.49a2.48,2.48,0,0,1,3.56-3c1.5.83,2.12,2.69,2.12,4.68h.82a9.56,9.56,0,0,0-.14-2.7,15.68,15.68,0,0,0-1-2.78,3.52,3.52,0,0,1,.32-3.65,20.75,20.75,0,0,0,1.51-2,20.06,20.06,0,0,0,1.5,2,3.57,3.57,0,0,1,.33,3.65,14.93,14.93,0,0,0-1,2.78,9.14,9.14,0,0,0-.14,2.7H510c0-2,.61-3.85,2.11-4.68A2.48,2.48,0,0,1,515.68,198.28Z"
            fillRule="evenodd"
          />
          <path
            d="M455.94,232.48h29.35v-18H455.94ZM467,221.85c1.5.83,2.12,2.7,2.12,4.68H470a8.88,8.88,0,0,0-.13-2.69,15.47,15.47,0,0,0-1-2.79,3.53,3.53,0,0,1,.33-3.65,22.26,22.26,0,0,0,1.5-2,20.36,20.36,0,0,0,1.5,2,3.55,3.55,0,0,1,.33,3.65,15.58,15.58,0,0,0-1,2.79,8.88,8.88,0,0,0-.13,2.69h.82c0-2,.61-3.85,2.11-4.68a2.47,2.47,0,0,1,3.56,3c-.35,1.11-1,1.49-1.15,1.49a1.88,1.88,0,0,0-.6-1.86,1.69,1.69,0,0,0-2.35.47,3.79,3.79,0,0,0-.52,1.59h1.53v1.13h-3.07a1.52,1.52,0,0,0,1.76,1.45s-.2,1.25-1.76.59a2.33,2.33,0,0,1-1,2,2.3,2.3,0,0,1-1-2c-1.56.66-1.77-.59-1.77-.59a1.53,1.53,0,0,0,1.77-1.45h-3.08v-1.13h1.53a3.79,3.79,0,0,0-.52-1.59,1.69,1.69,0,0,0-2.35-.47,1.89,1.89,0,0,0-.59,1.86c-.18,0-.81-.38-1.16-1.49A2.47,2.47,0,0,1,467,221.85Z"
            fillRule="evenodd"
          />
          <path
            d="M493.85,232.48h29.37v-18H493.85Zm11.08-10.63c1.5.83,2.12,2.7,2.12,4.68h.82a9.49,9.49,0,0,0-.14-2.69,15.47,15.47,0,0,0-1-2.79,3.52,3.52,0,0,1,.32-3.65,21.07,21.07,0,0,0,1.51-2,20.36,20.36,0,0,0,1.5,2,3.57,3.57,0,0,1,.33,3.65,14.75,14.75,0,0,0-1,2.79,9.08,9.08,0,0,0-.14,2.69H510c0-2,.61-3.85,2.11-4.68a2.48,2.48,0,0,1,3.56,3c-.35,1.11-1,1.49-1.14,1.49a1.92,1.92,0,0,0-.61-1.86,1.69,1.69,0,0,0-2.35.47,4,4,0,0,0-.52,1.59h1.53v1.13h-3.08a1.53,1.53,0,0,0,1.78,1.45s-.22,1.25-1.78.59a2.31,2.31,0,0,1-1,2,2.27,2.27,0,0,1-1-2c-1.56.66-1.77-.59-1.77-.59a1.54,1.54,0,0,0,1.77-1.45h-3.07v-1.13H506a3.79,3.79,0,0,0-.52-1.59,1.68,1.68,0,0,0-2.34-.47,1.88,1.88,0,0,0-.6,1.86c-.18,0-.81-.38-1.16-1.49A2.48,2.48,0,0,1,504.93,221.85Z"
            fillRule="evenodd"
          />
        </g>
      </g>
      <g>
        <path d="M197.74,157.42a1.54,1.54,0,1,0-1.53-1.54A1.54,1.54,0,0,0,197.74,157.42Z" />
        <path d="M197.74,162.19h-1.23l-.72-3.72L195,158s0-.21.67-.83a3.18,3.18,0,0,0,2.05,1.14,3.22,3.22,0,0,0,2.08-1.14,4.81,4.81,0,0,1,.72.83l-.72.46-.73,3.78Z" />
        <path d="M193.93,177.69s-2.31,4.78,1.61,11.53h2.84s.2-1.69-2.25-5.06C193.49,180.53,193.93,177.69,193.93,177.69Z" />
        <g>
          <g>
            <path d="M108.09,226.19c-1.12,1.14-2.09,4.47-7.31,4.47H89.68V219.31h12.41v-2.62H89.68v-9.53h12c2.38,0,3.4,1.65,4.8,2a1.76,1.76,0,0,0,.71,0l-1.84-4.56H80.7v.43c0,.69,3.34,1,3.34,2.59v25.5h23.19l2-7.38A1.38,1.38,0,0,0,108.09,226.19Z" />
            <path d="M90.94,201.84c1.63-.36,7.59-3.63,11.43-5.67H95.66l-6,5.38A1.41,1.41,0,0,0,90.94,201.84Z" />
            <path d="M167.93,226.19c-1.11,1.14-2.09,4.47-7.32,4.47h-11.1V219.31h12.41v-2.62H149.51v-9.53h12c2.39,0,3.41,1.65,4.81,2a1.75,1.75,0,0,0,.71,0l-1.85-4.56H140.54v.43c0,.69,3.33,1,3.33,2.59v25.5h23.21l2-7.38A1.39,1.39,0,0,0,167.93,226.19Z" />
            <path d="M75.22,229.78V204.65h-9v.43c0,.69,3.34,1,3.34,2.59V223.5c0,4.76-3.69,7.72-8.34,7.72s-8.34-3.13-8.34-7.9V204.65H44.56v.43c0,.74,2.71,1,2.71,3v14.61c0,7,5.17,11,14,11a12.25,12.25,0,0,0,8.34-3.31v2.82h8.34v-.43C77.92,232,75.22,231.76,75.22,229.78Z" />
            <path d="M129.45,217.64v-.08c3.89-1,6.81-2.7,6.81-6.26,0-5.67-6.42-6.65-12.25-6.65h-13.7v.4c0,.78,3.33.95,3.33,2.62v25.5h10.85c9.94,0,14.8-4.14,14.8-8.46C139.29,219.94,134.64,218.14,129.45,217.64Zm-10.18-10.49h4.63c4,0,7.4,1.14,7.4,4.69,0,3.11-3.24,4.95-9.4,4.95h-2.63Zm3.38,23.47h-3.38V219.31h5.12c6,0,9.61,1.89,9.61,5.94C134,228.94,129.68,230.62,122.65,230.62Z" />
            <path d="M198.88,212.07c-.27-2.25-.49-4.5-.54-6.75a22.85,22.85,0,0,0-8.2-1.4c-7.56,0-19,4.19-19,15.13,0,10.52,9.67,14.84,16.68,14.84,4.59,0,9.07-1.13,11.94-3.46l.21-2.52a22,22,0,0,1-10.37,2.92,13.54,13.54,0,0,1-13.49-13.27c0-6.57,5.4-10.84,12.58-10.84,2.63,0,5.67.61,7.63,2.37,1,.85,1.58,3,2.13,3Z" />
            <path d="M65.75,240a3.15,3.15,0,0,0-.75.27,26,26,0,0,1-9.77,1.89c-3.41,0-10.08-.59-20.66-6.41l-5.52-3.09A19.55,19.55,0,0,0,42,215.13c-.05-8.09-5.68-18-20-18-10,0-21.19,7.7-21.19,18.57,0,10,7.36,18.35,20.34,18.35l1.24,0,6.14,3.86c8.33,5.16,13.64,7.32,21.21,7.32a34.74,34.74,0,0,0,15.9-4c.34-.16.6-.32.6-.64A.48.48,0,0,0,65.75,240Zm-59-25.7c0-10.19,8.65-13.41,13.36-13.41a16,16,0,0,1,16,16.24c0,8.81-6.65,13.14-13.5,13.14C12.6,230.27,6.73,222.12,6.73,214.3Z" />
            <path d="M102.34,185.74c-.53.57-1,2.25-3.52,2.25H93.48v-5.74h6v-1.32h-6v-4.82h5.78c1.15,0,1.65.83,2.31,1a.91.91,0,0,0,.35,0l-.89-2.3H89.15v.22c0,.34,1.61.49,1.61,1.31v12.9h11.17l1-3.74A.66.66,0,0,0,102.34,185.74Z" />
            <path d="M65.34,185.74c-.53.57-1,2.25-3.52,2.25H56.47v-5.74h6v-1.32h-6v-4.82h5.79c1.14,0,1.64.83,2.31,1a.85.85,0,0,0,.34,0l-.89-2.3H52.15v.22c0,.34,1.61.49,1.61,1.31v12.9H64.93l1-3.74A.63.63,0,0,0,65.34,185.74Z" />
            <path d="M78.39,175H72.83v.22c0,.34,1.59.49,1.59,1.31v12.9h2.72v0h1.92c4.71,0,8.59-2.95,8.59-7.29S84.43,175,78.39,175Zm1.24,13.13H77.14V176.26h.35c6.52,0,7.83,3.68,7.83,6.27A5.82,5.82,0,0,1,79.63,188.12Z" />
            <path d="M18,174.84v.22c0,.34,1.61.49,1.61,1.31v12.9h2.71V174.84Z" />
            <path d="M43.14,174.84H38.82v.22c0,.34,1.6.49,1.6,1.31v12.9H50.87l1-3.74a.64.64,0,0,0-.56.21c-.53.57-1,2.25-3.52,2.25H43.14Z" />
            <path d="M29.65,174.84H25.33v.22c0,.34,1.61.49,1.61,1.31v12.9H37.39l1-3.74a.64.64,0,0,0-.56.21c-.54.57-1,2.25-3.52,2.25H29.65Z" />
            <path d="M17.42,171.83H13.53v.23c0,.35,1.41.19,1.42.95a9.48,9.48,0,0,1-.55,1.88l-4.31,11.55H10L4.12,171.83H0v.23c0,.35,1.42.25,1.87,1.36l6.78,16.13h2Z" />
          </g>
          <path d="M155.6,134.17s-5.76,5.44-.11,14.69c-4.46-2.18-5.65-2.72-10-2.51s-6.63,1.31-6.74,5c0,0-8-11.31,3.48-14.58Z" />
          <path d="M179.43,128.84s-6,6.09.11,15.34c-4.46-2.17-9.9-2.4-14.26-2.17s-6.52,2-6.63,5.65c0,0-7.18-10.66,3-15Z" />
          <path d="M179.75,144.62l-19.67,3.66s-15.47,7.65,4.34,24.29c0,0-4.47-6,2.28-6.09a19.78,19.78,0,0,1,10.28,2.4,11.85,11.85,0,0,1,1.63.76S168,156.69,179.75,144.62Z" />
          <path d="M138.85,152.34l16.86-3.14s-10.12,9.45,6,23.16c-4.78-1.74-8.37-4.79-14-4.57-2.72,0-4,1.74-3.7,3.91C144,171.7,131.45,162.46,138.85,152.34Z" />
          <path d="M194.85,142.57s-29.43,6.2-14,26.22c0,0-1.58-5.88,5.71-5.66s7.38,0,8.38.11C195,163.24,181.74,154.59,194.85,142.57Z" />
          <path d="M194.12,189.22h-45.8s-3.48-2.79-6.48-9.69a33.5,33.5,0,0,0-2.21-3.55c-2.36-3.08-7.85-6.07-8-6.46s-.51-1.55,1.15-.94l4,2.42,7.23,1.85a129.43,129.43,0,0,0,30,1.85l.54-1.58,3.16-.22,1.19-2.45,9.52-2.83.88-2.12,8.27-2.72s2.88,4.62-4,12.84C193.58,175.62,189.06,180,194.12,189.22Z" />
          <path d="M122.09,157.72l1.88.06c-2.88-2-5-3.55-5.64-4-1.36-.77-.87.38-.87.38Z" />
          <path d="M133.6,173.29c-8.11-6.46-2.37-14.93-2.37-14.93l-12.45.38c-5.67,4.46-3.33,11.32,1.46,14.36a5.25,5.25,0,0,0,1.85.85c6.39,1.39,9-2.1,9-2.1h0C132.59,172.61,133.6,173.29,133.6,173.29Zm-10.32.4c-1.81-.29-3.12-3.08-.38-3.91,2.25-.68,5.6.69,7.95,1.91C128.26,174.55,123.28,173.69,123.28,173.69Z" />
          <path d="M130.77,162.47s-.76,1.37-.14,2.25l5.38,4.52,5.5,1.48Z" />
          <path d="M146.49,127.51c0-.45.14-3,.14-3.82s.38-.91.44-.13.89,9.39.92,10.11-.05.91-.28,1a6.33,6.33,0,0,1-.9.11c-.25,0-.38-.07-.4-.5s0-4.92,0-5.22Z" />
          <path d="M145.31,127.18a2.72,2.72,0,0,1,.34-1.6c.27-.49-.36-.37-1.72-.24s-1.77.91-3.48,1a8.2,8.2,0,0,1-2.86-.21c-.43-.11-.27.28,0,.47a10.08,10.08,0,0,1,1.28.91.5.5,0,0,1-.09.61,12.21,12.21,0,0,1-1.29.83c-.2.09-.37.26.29.47a6.85,6.85,0,0,0,4.57,0,5.66,5.66,0,0,1,2.09-.65c.88,0,1.55.26,1.1-.52A3.67,3.67,0,0,1,145.31,127.18Z" />
          <path d="M170,116.52c0-.62.08-.64.2-.64s.31-.13.35.48.84,12.6.86,13,0,.47-.34.57a3,3,0,0,1-.77.17c-.3,0-.51-.06-.51-.4s.1-8.2.15-9.11C169.94,120.55,170,117.13,170,116.52Z" />
          <path d="M169.23,119.86a2.81,2.81,0,0,0-.8,1.89,2.23,2.23,0,0,0,.45,1.89.45.45,0,0,1,.08.38s-.18.16-.47-.06a4.79,4.79,0,0,0-2.44-.89c-1.36,0-2.66,1.25-4.27,1.21a9.09,9.09,0,0,1-2.78-.51,3.77,3.77,0,0,0-2.17.07,9.15,9.15,0,0,0-.91.35c-.17.11-.55.15-.19-.46s.53-1.31,2.57-2.36a13.83,13.83,0,0,1,2.17-.64,11.5,11.5,0,0,0,1.81-1,2.45,2.45,0,0,1,2.39-.35c1.46.35,1,.92,2.59.73a3,3,0,0,0,1.11-.17,3.21,3.21,0,0,1,.77-.26C169.24,119.64,169.37,119.63,169.23,119.86Z" />
          <path d="M185.36,133c0-.22.08-1.79.1-2.34s.38-.66.42-.11.69,12.68.69,13.34,0,.75-.27.88a4.82,4.82,0,0,1-.68.33c-.31.14-.46.11-.46-.27s.06-8.89.11-10.06C185.27,134.8,185.36,133.25,185.36,133Z" />
          <path d="M178.62,135.11c-.21-.13-.38-.45,0-.38a4.1,4.1,0,0,0,2.35-.21,5.77,5.77,0,0,1,2.41-1.07c1-.06,1.46,0,1.1.49a2.25,2.25,0,0,0-.67,1.67,4.24,4.24,0,0,0,.5,1.8c.21.36.24.56-.17.51a15.39,15.39,0,0,0-2.71-.29,6.1,6.1,0,0,0-2.38.58c-.4.25-.55,0-.37-.18s1.18-1.1,1.45-1.36.25-.44,0-.59S178.83,135.24,178.62,135.11Z" />
        </g>
      </g>
    </g>
  </svg>
)

export default LogoVilleDeQuebecEntenteDeDeveloppementCulturel
