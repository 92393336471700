export default {
  body: ["SuisseIntl"],
  system: [
    `-apple-system`,
    `BlinkMacSystemFont`,
    `Helvetica Neue`,
    `Arial`,
    `sans-serif`,
    `Apple Color Emoji`,
    `Segoe UI Emoji`,
    `Segoe UI Symbol`,
    `Noto Color Emoji`,
  ],
}
