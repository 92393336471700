// vendors
import React from "react"

import cressWhite from "./logo-rotonde-cress-white.png"
import cressBlack from "./logo-rotonde-cress-black.png"

const LogoRotonde = ({ light, ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 360 216" {...props}>
    <g>
      <g>
        <path d="M152.05,51.33a.75.75,0,0,1,.75-.74h2.58a.77.77,0,0,1,.75.74v23h13.29a.75.75,0,0,1,.75.74v2.15a.76.76,0,0,1-.75.75H152.8a.76.76,0,0,1-.75-.75Z" />
        <path d="M179.48,77l12-26.37a.88.88,0,0,1,.66-.43h.39a.88.88,0,0,1,.67.43L205.2,77a.69.69,0,0,1-.3.93.75.75,0,0,1-.36.07h-2.47a.91.91,0,0,1-.9-.59L198.74,72H185.9c-.78,1.81-1.6,3.57-2.39,5.37a1,1,0,0,1-.9.59h-2.47a.68.68,0,0,1-.73-.64A.63.63,0,0,1,179.48,77Zm17.75-8.31L192.41,58h-.2l-4.78,10.73Z" />
        <path d="M152.05,91.88a.77.77,0,0,1,.75-.75h10.66A8.46,8.46,0,0,1,172,99.51h0a8.69,8.69,0,0,1-5.8,8l5.37,10a.74.74,0,0,1-.67,1.13h-3.06a.72.72,0,0,1-.62-.35L162,107.83h-5.92v10a.77.77,0,0,1-.75.74H152.8a.75.75,0,0,1-.75-.74Zm11.09,12.58a4.87,4.87,0,0,0,4.78-4.86,4.8,4.8,0,0,0-4.78-4.66h-7v9.52Z" />
        <path d="M192.41,90.74a14.11,14.11,0,1,1-14.06,14.16h0a14.08,14.08,0,0,1,14-14.15Zm0,24.3a10.19,10.19,0,1,0-10.15-10.23v.08A10.19,10.19,0,0,0,192.41,115Z" />
        <path d="M159.22,135h-6.38a.74.74,0,0,1-.75-.73h0V132a.74.74,0,0,1,.74-.74h16.86a.74.74,0,0,1,.74.74h0v2.2a.74.74,0,0,1-.74.74H163.3v23a.77.77,0,0,1-.75.75H160a.78.78,0,0,1-.75-.75Z" />
        <path d="M192.41,131.29a14.11,14.11,0,1,1-14.06,14.16v0a14.08,14.08,0,0,1,14-14.14Zm0,24.29a10.19,10.19,0,1,0-10.15-10.23v.08a10.18,10.18,0,0,0,10.15,10.15Z" />
        <path d="M215,132a.72.72,0,0,1,.74-.7h1l17,19.67h0V132.42a.74.74,0,0,1,.74-.74H237a.78.78,0,0,1,.74.74V158.8a.72.72,0,0,1-.74.7h-1l-17-20.22h0v19.09a.74.74,0,0,1-.74.74h-2.47a.78.78,0,0,1-.74-.74Z" />
        <path d="M250.92,132a.72.72,0,0,1,.7-.74h9a13.72,13.72,0,1,1,.74,27.43h-9.74a.73.73,0,0,1-.7-.75Zm9.17,23a10,10,0,0,0,0-20h-5.18v20Z" />
        <path d="M283.77,132a.74.74,0,0,1,.74-.74h15.68a.74.74,0,0,1,.74.74v2.2a.74.74,0,0,1-.74.74H287.8v8h10.47a.78.78,0,0,1,.74.74v2.19a.74.74,0,0,1-.73.75H287.8V155h12.39a.75.75,0,0,1,.74.75V158a.74.74,0,0,1-.73.75H284.51a.74.74,0,0,1-.74-.74h0Z" />
      </g>
      {light && (
        <image
          width="193"
          height="216"
          transform="translate(-11.75 5.02)"
          href={cressWhite}
        />
      )}
      {!light && (
        <image
          width="193"
          height="216"
          transform="translate(-11.75 5.02)"
          href={cressBlack}
        />
      )}
      <g>
        <g>
          <path d="M215.25,78.83a.27.27,0,0,1,.26-.28h3.88a3.07,3.07,0,0,1,3.1,3,3.16,3.16,0,0,1-2.11,2.89l2,3.61a.27.27,0,0,1-.07.37.26.26,0,0,1-.17,0H221a.28.28,0,0,1-.23-.13l-1.89-3.77h-2.15V88.2a.28.28,0,0,1-.27.27h-.92a.27.27,0,0,1-.27-.27h0Zm4,4.56A1.77,1.77,0,0,0,221,81.63a1.74,1.74,0,0,0-1.74-1.69h-2.53v3.45Z" />
          <path d="M224.71,78.83a.27.27,0,0,1,.26-.28h5.7a.27.27,0,0,1,.27.27h0v.79a.27.27,0,0,1-.27.27h-4.49V82.8H230a.28.28,0,0,1,.27.27v.79a.27.27,0,0,1-.27.27h-3.8v3.06h4.49a.27.27,0,0,1,.27.27h0v.79a.27.27,0,0,1-.27.27H225a.27.27,0,0,1-.27-.27h0Z" />
          <path d="M233.09,78.67a.27.27,0,0,1,.27-.26h.36l6.17,7.15h0V78.83a.27.27,0,0,1,.26-.28h.92a.28.28,0,0,1,.27.28V88.4a.27.27,0,0,1-.27.26h-.37l-6.18-7.34h0v6.93a.27.27,0,0,1-.27.27h-.9a.28.28,0,0,1-.27-.27Z" />
          <path d="M248.53,78.41A5,5,0,0,1,252,79.74a.26.26,0,0,1,0,.37s0,0,0,0l-.63.64c-.1.13-.22.13-.35,0a3.88,3.88,0,0,0-2.46-1,3.7,3.7,0,0,0,0,7.39,3.87,3.87,0,0,0,2.45-.94.27.27,0,0,1,.34,0l.65.64a.27.27,0,0,1,0,.38h0a4.87,4.87,0,0,1-3.46,1.38,5.13,5.13,0,0,1-.45-10.25h.45Z" />
          <path d="M258.37,78.41a5.13,5.13,0,1,1-5.11,5.15h0a5.12,5.12,0,0,1,5.1-5.14Zm0,8.83a3.7,3.7,0,1,0-3.69-3.71v0A3.71,3.71,0,0,0,258.37,87.24Z" />
          <path d="M265.52,78.67a.27.27,0,0,1,.27-.26h.36l6.18,7.15h0V78.83a.27.27,0,0,1,.26-.28h.92a.28.28,0,0,1,.27.28V88.4a.27.27,0,0,1-.27.26h-.37L267,81.32h0v6.93a.27.27,0,0,1-.27.27h-.9a.28.28,0,0,1-.27-.27Z" />
          <path d="M277.92,79.89H275.6a.27.27,0,0,1-.27-.27v-.79a.27.27,0,0,1,.26-.28h6.13a.28.28,0,0,1,.27.28v.79a.28.28,0,0,1-.27.27H279.4v8.36a.28.28,0,0,1-.27.27h-.94a.28.28,0,0,1-.27-.27Z" />
          <path d="M283.52,78.83a.28.28,0,0,1,.27-.28h3.88a3.08,3.08,0,0,1,3.1,3,3.16,3.16,0,0,1-2.11,2.89l1.95,3.61a.27.27,0,0,1-.07.37.26.26,0,0,1-.17,0h-1.11a.28.28,0,0,1-.23-.13l-1.89-3.77H285V88.2a.28.28,0,0,1-.27.27h-.93a.28.28,0,0,1-.27-.27Zm4,4.56a1.78,1.78,0,0,0,1.74-1.76,1.74,1.74,0,0,0-1.74-1.69H285v3.45Z" />
          <path d="M293,78.83a.27.27,0,0,1,.26-.28H299a.27.27,0,0,1,.27.27h0v.79a.27.27,0,0,1-.27.27h-4.5V82.8h3.8a.27.27,0,0,1,.27.27v.79a.26.26,0,0,1-.25.27H294.5v3.06H299a.27.27,0,0,1,.27.27v.79a.27.27,0,0,1-.27.27h-5.69a.27.27,0,0,1-.27-.27Z" />
          <path d="M301.37,78.83a.27.27,0,0,1,.26-.28h3.88a3.07,3.07,0,0,1,3.1,3,3.17,3.17,0,0,1-2.1,2.89l1.95,3.61a.27.27,0,0,1-.07.37.24.24,0,0,1-.18,0H307.1a.26.26,0,0,1-.22-.13L305,84.62h-2.15v3.63a.28.28,0,0,1-.27.27h-.92a.27.27,0,0,1-.27-.27Zm4,4.56a1.76,1.76,0,0,0,1.73-1.76,1.74,1.74,0,0,0-1.73-1.69h-2.54v3.45Z" />
        </g>
        <g>
          <path d="M215.25,93.93a.27.27,0,0,1,.27-.27h.94a.28.28,0,0,1,.27.27v8.37h3.85a.26.26,0,0,1,.27.25v.8a.27.27,0,0,1-.27.27h-5.06a.27.27,0,0,1-.27-.27h0Z" />
          <path d="M221.11,97.33c-.09-.09-.06-.23.06-.34a2.37,2.37,0,0,0,.92-1.77.76.76,0,0,1-.3,0,.88.88,0,1,1,0-1.76h0c.49,0,1.07.3,1.07,1.4a3.66,3.66,0,0,1-1.24,2.6c-.13.12-.24.12-.34,0Z" />
          <path d="M223.32,103.25l4.36-9.58a.42.42,0,0,1,.25-.16h.14a.34.34,0,0,1,.24.16l4.34,9.58a.25.25,0,0,1-.1.34.32.32,0,0,1-.14,0h-.9a.31.31,0,0,1-.32-.22l-.89-1.94h-4.65c-.28.65-.58,1.29-.87,1.94a.36.36,0,0,1-.33.22h-.89a.25.25,0,0,1-.27-.23A.32.32,0,0,1,223.32,103.25Zm6.44-3L228,96.33h-.07l-1.74,3.9Z" />
          <path d="M234.07,93.93a.28.28,0,0,1,.27-.27h3.88a3.07,3.07,0,0,1,3.1,3,3.16,3.16,0,0,1-2.11,2.89l1.95,3.62a.26.26,0,0,1-.09.37.28.28,0,0,1-.15,0h-1.11a.28.28,0,0,1-.23-.13l-1.89-3.77h-2.15v3.63a.28.28,0,0,1-.27.27h-.93a.28.28,0,0,1-.27-.27Zm4,4.57a1.77,1.77,0,0,0,1.74-1.77A1.74,1.74,0,0,0,238.1,95h-2.53v3.5Z" />
          <path d="M244.9,95h-2.32a.28.28,0,0,1-.27-.28v-.79a.28.28,0,0,1,.27-.27h6.12a.27.27,0,0,1,.27.27h0v.79a.27.27,0,0,1-.26.28h-2.33v8.35a.28.28,0,0,1-.27.27h-.94a.28.28,0,0,1-.27-.27Z" />
        </g>
        <g>
          <path d="M219.66,108.63A4.91,4.91,0,0,1,223.1,110a.27.27,0,0,1,0,.38l0,0-.63.64c-.1.13-.23.13-.36,0a3.81,3.81,0,0,0-2.46-1,3.7,3.7,0,1,0,2.44,6.45.26.26,0,0,1,.35,0l.65.64a.28.28,0,0,1,0,.38,4.87,4.87,0,0,1-3.46,1.38,5.13,5.13,0,0,1-.45-10.25,3.38,3.38,0,0,1,.45,0Z" />
          <path d="M225.15,109.05a.28.28,0,0,1,.27-.27h.94a.27.27,0,0,1,.27.27h0v4h5.21v-4a.27.27,0,0,1,.27-.27h.94a.28.28,0,0,1,.27.27v9.42a.28.28,0,0,1-.27.27h-.94a.27.27,0,0,1-.27-.27h0v-4.11h-5.21v4.11a.27.27,0,0,1-.27.27h-.94a.28.28,0,0,1-.27-.27Z" />
          <path d="M240.46,108.63a5.13,5.13,0,1,1-5.11,5.15h0a5.12,5.12,0,0,1,5.1-5.14Zm0,8.83a3.7,3.7,0,1,0-3.68-3.72v0A3.7,3.7,0,0,0,240.46,117.46Z" />
          <path d="M247.62,109.05a.28.28,0,0,1,.27-.27h3.87a3.06,3.06,0,0,1,3.1,3,3.14,3.14,0,0,1-2.1,2.89l2,3.62a.26.26,0,0,1-.09.37.28.28,0,0,1-.15,0h-1.11a.28.28,0,0,1-.23-.13l-1.89-3.77h-2.15v3.63a.28.28,0,0,1-.27.27h-.93a.28.28,0,0,1-.27-.27Zm4,4.57a1.77,1.77,0,0,0,1.73-1.77,1.74,1.74,0,0,0-1.73-1.69h-2.53v3.46Z" />
          <path d="M257.08,109.05a.28.28,0,0,1,.27-.27h5.7a.28.28,0,0,1,.27.27v.79a.28.28,0,0,1-.27.28h-4.5V113h3.8a.28.28,0,0,1,.27.27v.8a.28.28,0,0,1-.27.27h-3.8v3h4.5a.28.28,0,0,1,.27.28v.78a.28.28,0,0,1-.27.27h-5.7a.28.28,0,0,1-.27-.27Zm1.77-1.4-.2-.4a.21.21,0,0,1,.09-.27l2.23-1.29c.08-.06.3-.09.37,0l.33.63a.23.23,0,0,1-.06.32l0,0-2.39,1c-.19.15-.28.12-.34,0Z" />
          <path d="M269.82,108.63a5.14,5.14,0,0,1,3.46,1.33.27.27,0,0,1,0,.38l0,0-.64.67c-.11.12-.23.11-.37,0a3.81,3.81,0,0,0-2.42-1,3.7,3.7,0,0,0,0,7.39A5.29,5.29,0,0,0,272,117v-1.6h-1.4a.26.26,0,0,1-.27-.25h0v-.83a.26.26,0,0,1,.25-.27h2.61a.27.27,0,0,1,.26.27v3.44a.34.34,0,0,1-.11.23,7.17,7.17,0,0,1-3.53.94,5.13,5.13,0,0,1,0-10.25Z" />
          <path d="M275.7,109.05a.27.27,0,0,1,.27-.27h3.87a3.06,3.06,0,0,1,3.1,3,3.16,3.16,0,0,1-2.11,2.89l1.95,3.62a.26.26,0,0,1-.09.37.28.28,0,0,1-.15,0h-1.11a.28.28,0,0,1-.23-.13l-1.89-3.77h-2.15v3.63a.28.28,0,0,1-.27.27H276a.27.27,0,0,1-.27-.27Zm4,4.57a1.77,1.77,0,0,0,1.74-1.77,1.74,1.74,0,0,0-1.74-1.69h-2.53v3.46Z" />
          <path d="M283.82,118.37l4.37-9.58a.34.34,0,0,1,.24-.16h.15a.34.34,0,0,1,.24.16l4.34,9.58a.25.25,0,0,1-.1.34.32.32,0,0,1-.14,0H292a.32.32,0,0,1-.33-.22l-.88-1.94h-4.65c-.29.65-.59,1.29-.87,1.94a.37.37,0,0,1-.33.22h-.89a.25.25,0,0,1-.25-.27A.18.18,0,0,1,283.82,118.37Zm6.45-3-1.75-3.9h-.07l-1.74,3.9Z" />
          <path d="M294.58,109.05a.28.28,0,0,1,.27-.27h3.25a3.17,3.17,0,0,1,0,6.34H296v3.35a.28.28,0,0,1-.27.27h-.93a.28.28,0,0,1-.27-.27Zm3.43,4.68a1.82,1.82,0,0,0,1.81-1.82A1.76,1.76,0,0,0,298,110.2h-2v3.53Z" />
          <path d="M303,109.05a.28.28,0,0,1,.27-.27h.94a.27.27,0,0,1,.27.27v4h5.21v-4a.28.28,0,0,1,.27-.27h.94a.28.28,0,0,1,.27.27v9.42a.28.28,0,0,1-.27.27H310a.28.28,0,0,1-.27-.27v-4.11h-5.21v4.11a.27.27,0,0,1-.27.27h-.94a.28.28,0,0,1-.27-.27Z" />
          <path d="M314,109.05a.28.28,0,0,1,.27-.27h.94a.28.28,0,0,1,.27.27v9.42a.28.28,0,0,1-.27.27h-.94a.28.28,0,0,1-.27-.27Z" />
          <path d="M322.59,108.63a5.12,5.12,0,0,1,5.12,5.12v0a5.18,5.18,0,0,1-2.9,4.63l1.72,2.73a.27.27,0,0,1-.06.38.34.34,0,0,1-.17,0h-1.11a.31.31,0,0,1-.23-.15l-1.53-2.59a5.93,5.93,0,0,1-.84.06,5.13,5.13,0,0,1,0-10.25Zm0,8.83a3.7,3.7,0,1,0-3.69-3.71v0A3.7,3.7,0,0,0,322.59,117.46Z" />
          <path d="M329.63,109.05a.28.28,0,0,1,.27-.27h1a.27.27,0,0,1,.27.27v5.87a2.38,2.38,0,1,0,4.76,0V109a.27.27,0,0,1,.27-.27h1a.28.28,0,0,1,.27.27v6a3.87,3.87,0,0,1-7.74,0Z" />
          <path d="M340,109.05a.28.28,0,0,1,.27-.27H346a.28.28,0,0,1,.27.27v.79a.28.28,0,0,1-.27.28h-4.5V113h3.8a.28.28,0,0,1,.27.27v.8a.28.28,0,0,1-.27.27h-3.8v3H346a.28.28,0,0,1,.27.28v.78a.28.28,0,0,1-.27.27h-5.7a.28.28,0,0,1-.27-.27Z" />
        </g>
      </g>
    </g>
  </svg>
)

export default LogoRotonde
