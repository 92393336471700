// vendors
import React from "react"

const LogoConseilDeLaCulture = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 381.17 157.5" {...props}>
    <g>
      <polygon points="51.44 42.23 74.03 55.27 74.03 29.19 51.44 42.23" />
      <polygon points="98.41 42.23 75.82 29.18 75.82 55.27 98.41 42.23" />
      <polygon points="51.44 71.41 74.03 84.45 74.03 58.37 51.44 71.41" />
      <polygon points="98.41 71.41 75.82 58.37 75.82 84.45 98.41 71.41" />
      <polygon points="51.44 100.59 74.03 113.64 74.03 87.55 51.44 100.59" />
      <polygon points="98.41 100.59 75.82 87.55 75.82 113.64 98.41 100.59" />
      <polygon points="98.41 13.04 75.82 0 75.82 26.09 98.41 13.04" />
      <polygon points="76.71 27.64 99.3 40.68 99.3 14.59 76.71 27.64" />
      <polygon points="101.98 71.41 124.58 84.45 124.58 58.37 101.98 71.41" />
      <polygon points="123.69 56.82 101.09 43.77 101.09 69.86 123.69 56.82" />
      <polygon points="47.87 71.41 25.27 58.37 25.27 84.45 47.87 71.41" />
      <polygon points="26.16 86 48.76 99.04 48.76 72.95 26.16 86" />
      <polygon points="0.89 13.04 23.49 26.09 23.49 0 0.89 13.04" />
      <polygon points="47.87 13.04 25.27 0 25.27 26.09 47.87 13.04" />
      <polygon points="22.59 27.64 0 14.59 0 40.68 22.59 27.64" />
      <g>
        <path d="M152.33,41.87c0,4.51,2,5.76,5.07,5.76s5.08-1.25,5.08-5.76V39.16h7.79v1.7c0,10-5.31,13.65-12.87,13.65s-12.86-3.61-12.86-13.65V26.3c0-10,5.31-13.65,12.86-13.65s12.87,3.61,12.87,13.65v.11h-7.79V25.29c0-4.52-2-5.75-5.08-5.75s-5.07,1.23-5.07,5.75Z" />
        <path d="M175.91,26.3c0-10,5.3-13.65,12.86-13.65s12.86,3.61,12.86,13.65V40.86c0,10-5.3,13.65-12.86,13.65s-12.86-3.61-12.86-13.65Zm7.78,15.57c0,4.51,2,5.76,5.08,5.76s5.08-1.25,5.08-5.76V25.29c0-4.52-2-5.75-5.08-5.75s-5.08,1.23-5.08,5.75Z" />
        <polygon points="208.4 53.95 208.4 13.22 216.92 13.22 227.75 37.36 227.86 37.36 227.86 13.22 234.97 13.22 234.97 53.95 227.53 53.95 215.63 27.66 215.51 27.66 215.51 53.95 208.4 53.95" />
        <path d="M259.63,24.56c-1.3-3.84-3-5.48-5.7-5.48s-4.29,1.69-4.29,4.46c0,6.15,17.38,6.6,17.38,19.35,0,7-4.8,11.62-13,11.62-6.32,0-11.23-3.44-13.31-11.45l7.67-1.64c.91,4.91,3.72,6.66,6,6.66a4.57,4.57,0,0,0,4.79-4.85c0-7.68-17.38-7.79-17.38-19.18,0-7,4.18-11.4,12.24-11.4,6.94,0,11.18,4.12,12.53,9.88Z" />
        <polygon points="273.79 53.95 273.79 13.22 295.11 13.22 295.11 20.1 281.57 20.1 281.57 29.46 291.9 29.46 291.9 36.35 281.57 36.35 281.57 47.06 295.68 47.06 295.68 53.95 273.79 53.95" />
        <rect x="302.44" y="13.22" width="7.79" height="40.73" />
        <polygon points="317.68 53.95 317.68 13.22 325.46 13.22 325.46 47.06 337.98 47.06 337.98 53.95 317.68 53.95" />
        <path d="M183.7,88.36c0,4.51,2,5.75,5.07,5.75s5.08-1.24,5.08-5.75V85.65h7.78v1.69c0,10-5.3,13.65-12.86,13.65s-12.86-3.61-12.86-13.65V72.78c0-10,5.3-13.65,12.86-13.65s12.86,3.61,12.86,13.65v.12h-7.78V71.77c0-4.51-2-5.75-5.08-5.75s-5.07,1.24-5.07,5.75Z" />
        <path d="M216.19,59.69v28.5c0,3.89,1.69,5.92,5.47,5.92s5.53-2,5.53-5.92V59.69H235V88.24c0,9-5.3,12.75-13.31,12.75s-13.25-3.78-13.25-12.75V59.69Z" />
        <polygon points="242.42 100.43 242.42 59.7 250.2 59.7 250.2 93.54 262.73 93.54 262.73 100.43 242.42 100.43" />
        <polygon points="287.95 59.7 287.95 66.58 278.87 66.58 278.87 100.43 271.08 100.43 271.08 66.58 262 66.58 262 59.7 287.95 59.7" />
        <path d="M300.81,59.69v28.5c0,3.89,1.69,5.92,5.47,5.92s5.53-2,5.53-5.92V59.69h7.79V88.24c0,9-5.31,12.75-13.32,12.75S293,97.21,293,88.24V59.69Z" />
        <path d="M345.32,100.43l-6.77-16.59h-3.72v16.59H327V59.69h11.29c9.36,0,13.82,4.52,13.82,12.19,0,5.08-1.81,8.75-6.15,10.38l7.67,18.17Zm-10.49-23h4c3.44,0,5.53-1.69,5.53-5.64s-2.09-5.64-5.53-5.64h-4Z" />
        <polygon points="358.41 100.43 358.41 59.7 379.73 59.7 379.73 66.58 366.2 66.58 366.2 75.94 376.52 75.94 376.52 82.83 366.2 82.83 366.2 93.54 380.3 93.54 380.3 100.43 358.41 100.43" />
        <path d="M145.77,61h4.72c3.87,0,6,1.67,6.18,6.14v5.45c-.15,4.46-2.31,6.13-6.18,6.13h-4.72Zm3.39,14.72h1.17c1.87,0,2.95-.88,2.95-3.53V67.55c0-2.5-.84-3.53-2.95-3.53h-1.17Z" />
        <polygon points="159.42 78.74 159.42 61.02 168.69 61.02 168.69 64.01 162.8 64.01 162.8 68.09 167.29 68.09 167.29 71.08 162.8 71.08 162.8 75.75 168.94 75.75 168.94 78.74 159.42 78.74" />
        <polygon points="145.78 99.1 145.78 81.38 149.16 81.38 149.16 96.11 154.6 96.11 154.6 99.1 145.78 99.1" />
        <path d="M155.47,99.1l4.39-17.71h4.35l4.26,17.71h-3.24l-.86-3.73h-4.68l-1,3.73Zm4.83-6.72h3.36L162,85.21H162Z" />
        <g>
          <path d="M145.77,138.38V127h3.67c2.47,0,3.81,1.11,3.81,3a3,3,0,0,1-2.28,3l2.42,5.44H152l-2.31-5.19H147v5.19Zm1.27-6.26h2.29c1.74,0,2.66-.65,2.66-2.09s-1.05-2-2.67-2H147Z" />
          <path d="M163.29,127V128H157.7v3.82h4v1.08h-4v4.36h5.9v1.08h-7.16V127Zm-1.17-2.84-2.07,2.24H159l1.4-2.24Z" />
          <path d="M173.67,132.89v5.49h-1v-1h0a3.6,3.6,0,0,1-3,1.21c-2.59,0-4.46-1.87-4.46-5.87s1.87-5.87,4.46-5.87c1.93,0,3.41.92,4.11,3.36l-1.2.23c-.57-1.81-1.52-2.51-2.85-2.51-1.67,0-3.25,1.14-3.25,4.79s1.58,4.79,3.25,4.79a2.44,2.44,0,0,0,2.73-2.53v-1h-2.57v-1.08Z" />
          <path d="M178.19,127v11.42h-1.26V127Z" />
          <path d="M185.46,126.8c2.8,0,4.46,2.06,4.46,5.87s-1.66,5.87-4.46,5.87-4.46-2.06-4.46-5.87S182.66,126.8,185.46,126.8Zm0,10.66c2,0,3.19-1.55,3.19-4.79s-1.18-4.79-3.19-4.79-3.19,1.55-3.19,4.79S183.45,137.46,185.46,137.46Z" />
          <path d="M192.45,138.38V127h1.81l5,8.79h.07V127h1.13v11.42h-1l-5.78-10.18h0v10.18Z" />
          <path d="M209.23,129.87a2.58,2.58,0,0,0-2.65-2c-1.33,0-2.12.63-2.14,1.77,0,2.5,6.42,1.87,6.42,5.53,0,1.66-.93,3.36-3.84,3.36-2.5,0-3.48-1.62-4.08-3l1.15-.41a3.13,3.13,0,0,0,3,2.31c1.68,0,2.51-.88,2.51-2.06,0-2.9-6.41-2.05-6.41-5.66,0-1.64,1.37-2.94,3.43-2.94a3.75,3.75,0,0,1,3.76,2.82Z" />
          <path d="M218.21,127h3.06c3.1,0,5.16,2,5.16,5.71,0,3.35-1.8,5.71-5.22,5.71h-3Zm1.26,10.34h1.71c2.62,0,4-1.56,4-4.63,0-3.34-1.58-4.63-3.92-4.63h-1.77Z" />
          <path d="M235.72,127V128h-5.58v3.82h4v1.08h-4v4.36H236v1.08h-7.16V127Z" />
          <path d="M251.92,139.29a2.51,2.51,0,0,1-2.53-1.5,4.08,4.08,0,0,1-2.48.75c-2.8,0-4.46-2.06-4.46-5.87s1.66-5.87,4.46-5.87,4.46,2.06,4.46,5.87A7.11,7.11,0,0,1,250.2,137a1.76,1.76,0,0,0,1.72,1Zm-4.55-4.61a2.72,2.72,0,0,1,2.13,1.17,6.92,6.92,0,0,0,.6-3.18c0-3.24-1.18-4.79-3.19-4.79s-3.19,1.55-3.19,4.79,1.18,4.79,3.19,4.79a2.75,2.75,0,0,0,1.85-.63,1.82,1.82,0,0,0-1.39-.94Z" />
          <path d="M255.16,127V135a2.75,2.75,0,0,0,5.47,0V127h1.26v8c0,2.28-1.64,3.61-4,3.61s-4-1.33-4-3.61v-8Z" />
          <path d="M272.15,127V128h-5.58v3.82h4v1.08h-4v4.36h5.9v1.08h-7.16V127ZM271,124.12l-2.07,2.24h-1.06l1.41-2.24Z" />
          <path d="M275,127h4.21a2.89,2.89,0,0,1,3.19,2.82,2.53,2.53,0,0,1-1.93,2.62l0,0a2.91,2.91,0,0,1,2.19,2.88c0,1.71-1,3.07-4.14,3.07H275Zm1.27,4.9h2.21c1.91,0,2.65-1,2.65-1.94,0-1.28-.69-1.88-2.14-1.88h-2.72Zm0,5.44h2.37c1.86,0,2.78-.63,2.78-2.05s-1.17-2.31-3-2.31h-2.18Z" />
          <path d="M292.35,127V128h-5.58v3.82h4v1.08h-4v4.36h5.89v1.08H285.5V127Z" />
          <path d="M302.5,135c-.51,2.12-1.68,3.58-4,3.58-2.56,0-4.36-1.87-4.36-5.87,0-3.71,1.61-5.87,4.36-5.87,2.12,0,3.35,1.24,3.86,3.33l-1.17.34c-.41-1.61-1.14-2.59-2.85-2.59s-2.94,1.52-2.94,4.79,1.3,4.79,3.19,4.79,2.44-1.33,2.79-2.72Z" />
          <path d="M152.74,145.93V147h-5.58v3.82h4v1.07h-4v4.37h5.9v1.07H145.9V145.93Z" />
          <path d="M158.88,147v10.33h-1.27V147H154.2v-1.08h8.09V147Z" />
          <path d="M169,145.93h3.06c3.1,0,5.16,2,5.16,5.7,0,3.36-1.8,5.71-5.22,5.71h-3Zm1.26,10.34H172c2.62,0,4-1.57,4-4.64,0-3.33-1.58-4.62-3.92-4.62H170.3Z" />
          <path d="M186.55,145.93V147H181v3.82h4v1.07h-4v4.37h5.9v1.07h-7.16V145.93Z" />
          <path d="M201.52,153.93c-.51,2.12-1.68,3.57-4,3.57-2.56,0-4.36-1.86-4.36-5.87,0-3.71,1.61-5.86,4.36-5.86,2.12,0,3.36,1.24,3.86,3.32l-1.17.35c-.41-1.61-1.14-2.6-2.84-2.6s-2.94,1.52-2.94,4.79,1.29,4.79,3.19,4.79c1.75,0,2.43-1.32,2.78-2.71Z" />
          <path d="M211.84,145.93v11.41h-1.27V151.9H205.1v5.44h-1.26V145.93h1.26v4.9h5.47v-4.9Z" />
          <path d="M213.93,157.34l3.51-11.41h1.88l3.51,11.41H221.5l-1.08-3.57h-4.09l-1.08,3.57Zm2.71-4.65h3.46l-1.64-5.88h-.17Z" />
          <path d="M226.17,145.93V154a2.75,2.75,0,0,0,5.47,0v-8.06h1.27v8c0,2.28-1.65,3.61-4,3.61s-4-1.33-4-3.61v-8Z" />
          <path d="M236.19,145.93h3.07c3.1,0,5.15,2,5.15,5.7,0,3.36-1.8,5.71-5.21,5.71h-3Zm1.27,10.34h1.7c2.63,0,4-1.57,4-4.64,0-3.33-1.58-4.62-3.92-4.62h-1.77Z" />
          <path d="M248.41,145.93v11.41h-1.26V145.93Z" />
          <path d="M259,145.93V147h-5.58v3.82h4v1.07h-4v4.37h5.9v1.07h-7.17V145.93Zm-3.65-2.85,1.4,2.25h-1.06l-2.06-2.25Z" />
          <path d="M261.65,157.34V145.93h3.67c2.47,0,3.81,1.11,3.81,3a3,3,0,0,1-2.28,3l2.42,5.44H267.9l-2.31-5.18h-2.67v5.18Zm1.27-6.26h2.29c1.74,0,2.66-.65,2.66-2.08s-1.05-2-2.68-2h-2.27Z" />
          <path d="M279.17,145.93V147h-5.58v3.82h4v1.07h-4v4.37h5.89v1.07h-7.16V145.93Z" />
          <path d="M280.91,153.72v-1h4v1Z" />
          <path d="M285.92,157.34l3.5-11.41h1.88l3.51,11.41h-1.33l-1.07-3.57h-4.09l-1.08,3.57Zm2.71-4.65h3.47l-1.65-5.88h-.17Z" />
          <path d="M296.77,157.34V145.93h4c2.43,0,3.49,1.39,3.49,3.19s-1.31,3.13-4.19,3.13h-2v5.09Zm1.27-6.16h2.15c2.1,0,2.8-.73,2.8-2.09s-.81-2.08-2.52-2.08H298Z" />
          <path d="M306.43,157.34V145.93h4c2.44,0,3.5,1.39,3.5,3.19s-1.31,3.13-4.19,3.13h-2v5.09Zm1.27-6.16h2.15c2.1,0,2.79-.73,2.79-2.09s-.8-2.08-2.51-2.08H307.7Z" />
          <path d="M314,157.34l3.51-11.41h1.88l3.51,11.41h-1.33l-1.08-3.57h-4.09l-1.07,3.57Zm2.71-4.65h3.47l-1.65-5.88h-.17Z" />
          <path d="M325,157.34V145.93h1.27v10.34h5.4v1.07Z" />
          <path d="M332.46,157.34,336,145.93h1.88l3.51,11.41H340L339,153.77h-4.1l-1.07,3.57Zm2.72-4.65h3.46L337,146.81h-.18Z" />
          <path d="M350.78,153.93c-.51,2.12-1.67,3.57-4,3.57-2.57,0-4.36-1.86-4.36-5.87,0-3.71,1.61-5.86,4.36-5.86,2.11,0,3.35,1.24,3.85,3.32l-1.17.35c-.41-1.61-1.14-2.6-2.84-2.6s-2.94,1.52-2.94,4.79,1.3,4.79,3.19,4.79,2.43-1.32,2.78-2.71Z" />
          <path d="M361.1,145.93v11.41h-1.26V151.9h-5.47v5.44H353.1V145.93h1.27v4.9h5.47v-4.9Z" />
          <path d="M371.36,145.93V147h-5.58v3.82h4v1.07h-4v4.37h5.9v1.07h-7.17V145.93Z" />
          <path d="M379.55,148.84a2.61,2.61,0,0,0-2.66-2c-1.33,0-2.12.64-2.13,1.78,0,2.49,6.41,1.86,6.41,5.53,0,1.66-.93,3.35-3.84,3.35-2.49,0-3.47-1.61-4.07-3l1.15-.41a3.11,3.11,0,0,0,3,2.3c1.67,0,2.51-.88,2.51-2,0-2.91-6.42-2.06-6.42-5.66,0-1.64,1.38-2.94,3.43-2.94a3.75,3.75,0,0,1,3.76,2.82Z" />
        </g>
      </g>
    </g>
  </svg>
)

export default LogoConseilDeLaCulture
